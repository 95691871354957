
var $ = require('jquery');
var dt = require('datatables.net-dt');
const { forEach } = require('jszip');
window.JSZip = require('jszip');
require('pdfmake');
require('datatables.net-buttons-dt');
// require( 'datatables.net-fixedcolumns');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/dataTables.buttons.min')();
require('datatables.net-buttons/js/buttons.flash.min')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-rowgroup-dt');

$(document).on('turbolinks:load', () => {
  var img64 = window.enterpriseLogo;

  let months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
  let categories = ['Ingresos por venta', 'Costo integral financiero', 'Otros Ingresos', 'Total', 'Costo de venta', 'Otros costos de venta', 'Costos totales', 'Hardcost', 'Softcost', 'Valor del terreno', 'Impuestos', 'Otros egresos'];
  let categories_bu = ['Ingresos por venta', 'Costo de venta', 'Utilidad bruta', 'Gastos de operación', 'Utilidad antes de impuestos', 'Costo integral financiero', 'Impuestos', 'Utilidad neta', 'Otros ingresos', 'Otros egresos', 'Saldo'];
  let categories_bcf = ['Total ingresos', 'Total egresos', 'Saldo inicial', 'SALDO FINAL','TOTAL INGRESOS','TOTAL EGRESOS', 'Ingresos por venta', 'Costo integral financiero', 'Otros Ingresos', 'Costo de venta', 'Gastos de operación', 'Impuestos', 'Otros egresos'];
  let categories_ipcf = ['Total ingresos', 'Total egresos', 'Saldo inicial', 'SALDO FINAL','TOTAL INGRESOS','TOTAL EGRESOS', 'Ingresos por venta', 'Costo integral financiero', 'Otros Ingresos', 'Total', 'Costo de venta', 'Otros costos de venta', 'Costos totales', 'Hardcost', 'Softcost', 'Valor del terreno', 'Impuestos', 'Otros egresos'];
  let type = $('#type-budget-report');
  let budget = $('#actual-budget-report');
  var numFile = 1;
  var columnsFixed = 2;
  var isOtherPage = true;
  var titleTable = "";
  var subtitle = "";
  var titleRangeMonths = "";
  var currentdate = new Date();
  var columnsVisible = range(0,12);
  var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
  var datetime = currentdate.getDate() + "/"
              + (currentdate.getMonth()+1)  + "/"
              + currentdate.getFullYear();

  var buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          if ( $("#bu-income-statement").length && row > 0 ) {
            return verifyNode(node);
          }

          return verifyNode(node);
        }
      }
    }
  };

  var buttonCommonExcel = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          return verifyNode(node);
        }
      }
    }
  };

  var profitability_table_rows = [];
  
  function verifyNode(node) {
    node = $(node).find(".data-value");

    return node.is("input") ? node.val() : node.text().trim();
  }

  if ($('#ip-sales-progress').length) {
    $('#ip-sales-progress').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Bt',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Avance de ventas",
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend: 'pdfHtml5',
          title: "Avance de ventas",
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.
                       Fecha de exportación: ${datetime}`,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        },
      ],
    });
  };

  if ($('#ip-total-collection-table').length) {

    if ($('#ip-commission').length) {
      title_document = 'Comisiones sobre venta';
      numFile = 2;
    }else{
      title_document = 'Cobranza total';
    }

    $('#ip-total-collection-table').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: title_document,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      Fecha de exportación: ${datetime}\n`,
          footer: true
        },
        {
          extend:'excelHtml5',
          title: title_document,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      Fecha de exportación: ${datetime}\n`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
            let last_row = $('row', sheet).length

            $('row:eq(0) c', sheet).attr( 's', '50' );
            $('row:eq(1) c', sheet).attr( 's', '50' );
            $('row:eq(2) c', sheet).attr( 's', '2' );
            $('row:last c', sheet).attr('s','2');
            $('c[r^="E'+last_row+'"], c[r^="F'+last_row+'"], c[r^="G'+last_row+'"]', sheet).attr('s','57');
            $('c[r^="H'+last_row+'"]', sheet).attr('s','60');
          },
          exportOptions: {
            rows: ':visible'
          }
        },
        {
          extend: 'pdfHtml5',
          title: title_document,
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: title_document,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.
                       Fecha de exportación: ${datetime}`,
          footer: true,
          exportOptions: {
            columns: function(idx, data, node) {
              if ($.inArray(idx, columnsVisible) < 0) {
                return false;
              }
              return true;
            },
            stripNewlines: false,
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        },
      ],
      "drawCallback":function(){
        var api = this.api()
        var sumColumn;
        var totalAmountFooter;
        var realRealPriceFooter;
        $(api.column(3).footer()).html('Total: ')
        let limit = 7;

        if ($('#ip-commission').length) {
          limit = 9
        }

        for (index = 4; index <= limit; index++) {
          if (index < limit) {

            if ($('#ip-commission').length) {
              if (index != 5) {
                sumColumn = sum(api.column(index, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
                $(api.column(index).footer()).html(sumColumn);
              }

              switch (index) {
                case 6:
                  realRealPriceFooter = sumColumn;
                  break;
                case 7:
                  totalAmountFooter = sumColumn;
                  break;
                default:
                  break;
              }
            } else {
              sumColumn = sum(api.column(index, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
              $(api.column(index).footer()).html(sumColumn);
  
              switch (index) {
                case 4:
                  realRealPriceFooter = sumColumn;
                  break;
                case 5:
                  totalAmountFooter = sumColumn;
                  break;
                default:
                  break;
              }
            }

          } else {
            totalAmountFooter = convertCurrencyToDouble(totalAmountFooter);
            realRealPriceFooter = convertCurrencyToDouble(realRealPriceFooter);
            let percentage = (totalAmountFooter / realRealPriceFooter);
            if (isNaN(percentage)) { percentage = 0 }
            percentage = (100 * percentage);
            percentage = percentage.toFixed(2);
            $("#total-percentage-progress-bar").css("width", `${percentage}%`);
            $("#total-percentage").html(`${percentage}%`);
          }
        }
      }
    });
  };

  function sum(data) {
    return data.flatten().reduce( function ( a, b ) {
      if ( typeof a === 'string' ) {
        a = a.replace(/[^\d.-]/g, '') * 1;
      }
      if ( typeof b === 'string' ) {
        b = b.replace(/[^\d.-]/g, '') * 1;
      }

      return a + b;
    }, 0 );
  }

  function sumFixed(data) {
    return data.flatten().reduce( function ( a, b ) {
      if ( typeof a === 'string' ) {
        a = a.replace(/[^\d.-]/g, '') * 1;
      }
      if ( typeof b === 'string' ) {
        b = b.replace(/[^\d.-]/g, '') * 1;
      }

      return (a + b).toFixed(2);
    }, 0 );
  }

  function convertCurrencyToDouble(currency)  {
    return Number(currency.replace(/[^0-9.-]+/g,""));
  }

  const dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  if($('.ip-cash-accounts-view').length) {
    let values = JSON.parse($('#accounts-chart').val());

    let names = values.map(function(x){ return x[0] });
    let amounts = values.map(function(x){ return x[1] });
    let total = amounts.reduce((a, b) => a + b, 0);
    let percentages = amounts.map(function(x) { return ((x / total) * 100).toFixed(2) });
    let colors = values.map(function(x){ return dynamicColors() });
    var ctx = document.getElementById("ip-cash-accounts-chart").getContext("2d");
    var options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Cajas o cuentas'
        }
      }
    };

    var barChartData = {
      labels: names,
      datasets: [{
        backgroundColor: colors,
        data: percentages
      }]
    };

    var myChart = new Chart(ctx, { type: 'pie', options: options, data: barChartData });
  }

  if($('.ip-cash-accounts-business-unit-view').length) {
    let values = JSON.parse($('#accounts-chart').val());

    let names = values.map(function(x){ return x[0] });
    let amounts = values.map(function(x){ return x[1] });
    let total = amounts.reduce((a, b) => a + b, 0);
    let percentages = amounts.map(function(x) { return ((x / total) * 100).toFixed(2) });
    let colors = values.map(function(x){ return dynamicColors() });

    var ctx = document.getElementById("ip-cash-accounts-business-unit-chart").getContext("2d");
    var options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Cajas o cuentas'
        }
      }
    };

    var barChartData = {
      labels: names,
      datasets: [{
        backgroundColor: colors,
        data: percentages
      }]
    };

    var myChart = new Chart(ctx, { type: 'pie', options: options, data: barChartData });
  }

  function changeFromReports(url, id) {
    localStorage.setItem('fromReport', url);
    localStorage.setItem('fromReportID', id);
  };

  $('#type-budget-report').on("change", function(){
    if ($("#bu-income-statement").length) {
      var columns = [...Array(52).keys()].map(i => i + 2);
    } else {
      var columns = [...Array(13).keys()].map(i => i + 2);
    }
    
    switch ($('#type-budget-report').val()) {
      case '1':
        if ($("#bu-income-statement").length) {
          month = [2,3,4,5];
        } else if($("#bu-cash-flow").length){
          month = [3];
        }else{
          month = [2];
        }
        break;
      case '2':
        if ($("#bu-income-statement").length) {
          month = [6,7,8,9];
        } else if($("#bu-cash-flow").length){
          month = [4];
        } else {
          month = [3];
        }
        break;
      case '3':
        if ($("#bu-income-statement").length) {
          month = [10,11,12,13];
        } else if($("#bu-cash-flow").length){
          month = [5];
        } else {
          month = [4];
        }
        break;
      case '4':
        if ($("#bu-income-statement").length) {
          month = [14,15,16,17];
        } else if($("#bu-cash-flow").length){
          month = [6];
        } else {
          month = [5];
        }
        break;
      case '5':
        if ($("#bu-income-statement").length) {
          month = [18,19,20,21];
        } else if($("#bu-cash-flow").length){
          month = [7];
        } else {
          month = [6];
        }
        break;
      case '6':
        if ($("#bu-income-statement").length) {
          month = [22,23,24,25];
        } else if($("#bu-cash-flow").length){
          month = [8];
        } else {
          month = [7];
        }
        break;
      case '7':
        if ($("#bu-income-statement").length) {
          month = [26,27,28,29];
        } else if($("#bu-cash-flow").length){
          month = [9];
        } else {
          month = [8];
        }
        break;
      case '8':
        if ($("#bu-income-statement").length) {
          month = [30,31,32,33];
        } else if($("#bu-cash-flow").length){
          month = [10];
        } else {
          month = [9];
        }
        break;
      case '9':
        if ($("#bu-income-statement").length) {
          month = [34,35,36,37];
        } else if($("#bu-cash-flow").length){
          month = [11];
        } else {
          month = [10];
        }
        break;
      case '10':
        if ($("#bu-income-statement").length) {
          month = [38,39,40,41];
        } else if($("#bu-cash-flow").length){
          month = [12];
        } else {
          month = [11];
        }
        break;
      case '11':
        if ($("#bu-income-statement").length) {
          month = [42,43,44,45];
        } else if($("#bu-cash-flow").length){
          month = [13];
        } else {
          month = [12];
        }
        break;
      case '12':
        if ($("#bu-income-statement").length) {
          month = [46,47,48,49];
        } else if($("#bu-cash-flow").length){
          month = [14];
        } else {
          month = [13];
        }
        break;
      default:
        if ($("#bu-income-statement").length) {
          month = [...Array(52).keys()].map(i => i + 2);
        } else {
          month = [...Array(13).keys()].map(i => i + 2);
        }
    }
    let hiddenColumns = columns.filter(element => !month.includes(element));
    tableIncomeStatementBU.columns(month).visible( true );
    tableIncomeStatementBU.columns(hiddenColumns).visible( false );
  });

  $(document.body).on('click', '*[class*="expand-"]', function(element) {
    if($("#bu-cash-flow").length){
      tableIncomeStatementBU.columns.adjust();
    }
  });

  if ( $("#bu-income-statement").length) {
    businessUnit = $("#business-unit-name").val();
    rangeMonths = $("#range-months").val();
    titleTable = "Edo de resultados comparativo";
    subtitle = `Unidad de negocio: ${businessUnit}`;
    titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
    columnsVisible = range(0,13);
  }

  if ($("#bu-real-income-statement").length) {
    businessUnit = $("#business-unit-name").val();
    rangeMonths = $("#range-months").val();
    titleTable = "Edo de resultados";
    subtitle = `Unidad de negocio: ${businessUnit}`;
    titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
    columnsVisible = range(0,13);
  }

  if ( $("#bu-cash-flow").length ) {
    businessUnit = $("#business-unit-name").val();
    rangeMonths = $("#range-months").val();
    titleTable = "Flujo de efectivo";
    subtitle = `Unidad de negocio: ${businessUnit}`;
    titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
    columnsFixed = 3;
  }

  if ( $("#ip-investor-report").length ) {
    projectName = $("#project-name").val();
    titleTable = "Avance financiero";
    subtitle = `Proyecto inmobiliario: ${projectName}`;
    columnsVisible = range(0,6);
    rangeMonths = $("#range-months").val();
    titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
    let original_table_income = {}
    let original_table_expense = {}

    $('.tr-incomes').each(function(){
      original_table_income[this.cells[1].outerText] = {
        'Mes actual': this.cells[2].outerText,
        'Acumulado': this.cells[3].outerText,
        'Proyectado': this.cells[4].outerText
      };
    });

    $('.tr-expenses').each(function(){
      original_table_expense[this.cells[1].outerText] = {
        'Mes actual': this.cells[2].outerText,
        'Acumulado': this.cells[3].outerText,
        'Proyectado': this.cells[4].outerText
      };
    });

    $('.hide-data-table').click(function () {
      let array_subcategories_showed_incomes = [];
      let array_subcategories_showed_expenses = [];
      let times_incomes = $('.tr-incomes').length;
      let times_expenses = $('.tr-expenses').length;
      let count = 0;
      let total_incomes = [0,0,0]
      let total_expenses = [0,0,0]
      $('.tr-incomes').empty();
      $('.tr-expenses').empty();

      $('.checkbox-subcategory-incomes').each(function(){
        if (this.checked) {
          array_subcategories_showed_incomes.push(this.value);
        }
      });

      $('.checkbox-subcategory-expenses').each(function(){
        if (this.checked) {
          array_subcategories_showed_expenses.push(this.value);
        }
      });

      array_subcategories_showed_incomes.forEach(function (value, i) {
          let actual_month = convertCurrencyToDouble(original_table_income[value]['Mes actual']);
          let acumulate_value = convertCurrencyToDouble(original_table_income[value]['Acumulado']);
          let projecting_value = convertCurrencyToDouble(original_table_income[value]['Proyectado']);
          let diference_value = projecting_value - acumulate_value;
          let percentage = 0;
          if (projecting_value != 0) {
            percentage = (100 * (acumulate_value/projecting_value)).toFixed(2);
          }
          total_incomes[0] += actual_month;
          total_incomes[1] += acumulate_value;
          total_incomes[2] += projecting_value;

          $('.tr-incomes')[i].innerHTML += '<td></td>' +
          '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap border-l-0 text-right pr-2">' +
            '<div class="text-sm text-gray-900 italic data-value">' + value + '</div>' +
          '</td>' +
          '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
            '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + original_table_income[value]['Mes actual'] + '</div>' +
          '</td>' +
          '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
            '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + original_table_income[value]['Acumulado'] + '</div>' +
          '</td>' +
          '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
            '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + original_table_income[value]['Proyectado'] + '</div>' +
          '</td>' +
          '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
            '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + diference_value.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>' +
          '</td>' +
          '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
            '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + percentage + '%</div>' +
          '</td>'

          count += 1
      });

      for(var i = count; i < times_incomes; i++){
        $('.tr-incomes')[i].innerHTML += '<td class="" style="display: none;"></td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>'
      }

      fill_total('tr-other-incomes',total_incomes);

      count = 0;
      let cell2 = convertCurrencyToDouble($('#tr-sales-incomes')[0].cells[2].outerText) + convertCurrencyToDouble($('#tr-integral-cost-positive')[0].cells[2].outerText) + total_incomes[0];
      let cell3_incomes = convertCurrencyToDouble($('#tr-sales-incomes')[0].cells[3].outerText) + convertCurrencyToDouble($('#tr-integral-cost-positive')[0].cells[3].outerText) + total_incomes[1];
      let cell4 = convertCurrencyToDouble($('#tr-sales-incomes')[0].cells[4].outerText) + convertCurrencyToDouble($('#tr-integral-cost-positive')[0].cells[4].outerText) + total_incomes[2];
      let cell5 = cell4 - cell3_incomes;
      let percentage = 0;

      if (cell4 != 0) {
        percentage = (100 * (cell3_incomes/cell4)).toFixed(2);
      }

      $('#tr-total-incomes')[0].cells[2].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell2.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-incomes')[0].cells[3].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell3_incomes.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-incomes')[0].cells[4].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell4.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-incomes')[0].cells[5].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell5.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-incomes')[0].cells[6].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + percentage + '%</div>'
      '</td>';

      array_subcategories_showed_expenses.forEach(function (value, i) {
        let actual_month = convertCurrencyToDouble(original_table_expense[value]['Mes actual']);
        let acumulate_value = convertCurrencyToDouble(original_table_expense[value]['Acumulado']);
        let projecting_value = convertCurrencyToDouble(original_table_expense[value]['Proyectado']);
        let diference_value = projecting_value - acumulate_value
        let percentage = 0
        if (projecting_value != 0) {
          percentage = (100 * (acumulate_value/projecting_value)).toFixed(2)
        }
        total_expenses[0] += actual_month;
        total_expenses[1] += acumulate_value;
        total_expenses[2] += projecting_value;

        $('.tr-expenses')[i].innerHTML += '<td></td>' +
        '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap border-l-0 text-right pr-2">' +
          '<div class="text-sm text-gray-900 italic data-value">' + value + '</div>' +
        '</td>' +
        '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
          '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + original_table_expense[value]['Mes actual'] + '</div>' +
        '</td>' +
        '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
          '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + original_table_expense[value]['Acumulado'] + '</div>' +
        '</td>' +
        '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
          '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + original_table_expense[value]['Proyectado'] + '</div>' +
        '</td>' +
        '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
          '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + diference_value.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>' +
        '</td>' +
        '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
          '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + percentage + '%</div>' +
        '</td>'

        count += 1
      });

      for(var i = count; i < times_expenses; i++){
        $('.tr-expenses')[i].innerHTML += '<td style="display: none;"></td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>' +
        '<td class="" style="display: none;">' +
        '</td>'
      }

      fill_total('tr-other-expenses',total_expenses);

      cell2 = convertCurrencyToDouble($('#tr-sale-costs')[0].cells[2].outerText) + convertCurrencyToDouble($('#tr-total-costs')[0].cells[2].outerText) + convertCurrencyToDouble($('#tr-integral-costs-negative')[0].cells[2].outerText) + convertCurrencyToDouble($('#tr-taxes')[0].cells[2].outerText) + total_expenses[0];
      let cell3_expenses = convertCurrencyToDouble($('#tr-sale-costs')[0].cells[3].outerText) + convertCurrencyToDouble($('#tr-total-costs')[0].cells[3].outerText) + convertCurrencyToDouble($('#tr-integral-costs-negative')[0].cells[3].outerText) + convertCurrencyToDouble($('#tr-taxes')[0].cells[3].outerText) + total_expenses[1];
      cell4 = convertCurrencyToDouble($('#tr-sale-costs')[0].cells[4].outerText) + convertCurrencyToDouble($('#tr-total-costs')[0].cells[4].outerText) + convertCurrencyToDouble($('#tr-integral-costs-negative')[0].cells[4].outerText) + convertCurrencyToDouble($('#tr-taxes')[0].cells[4].outerText) + total_expenses[2];
      cell5 = cell4 - cell3_expenses;
      percentage = 0;

      if (cell4 != 0) {
        percentage = (100 * (cell3_expenses/cell4)).toFixed(2);
      }

      $('#tr-total-expenses')[0].cells[2].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell2.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-expenses')[0].cells[3].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell3_expenses.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-expenses')[0].cells[4].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell4.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-expenses')[0].cells[5].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell5.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#tr-total-expenses')[0].cells[6].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + percentage + '%</div>'
      '</td>';
      $('#td-total-incomes')[0].cells[3].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell3_incomes.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#td-total-expenses')[0].cells[3].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
      '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + cell3_expenses.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
    '</td>';

      let total_balance = cell3_incomes - cell3_expenses;
      $('#tr-proyect-balance')[0].cells[3].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
      '<div class="text-sm text-gray-900 font-semibold data-value text-right">' + total_balance.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
    '</td>';

      tableIncomeStatementBU.destroy();
      tableIncomeStatementBU = $('#table-income-statement-report').DataTable({
        scrollY:        '68vh',
        scrollX:        true,
        scrollCollapse: true,
        paging:         false,
        fixedColumns:   {
            leftColumns: columnsFixed
        },
        responsive: true,
        "language": {
          "lengthMenu": "Mostrar _MENU_ registros por página",
          "zeroRecords": "Nada encontrado",
          "info": "Mostrando página _PAGE_ de _PAGES_",
          "infoEmpty": "No hay registros disponibles",
          "infoFiltered": "(filtrado de _MAX_ registros)",
          "sSearch": "Buscar:",
          "oPaginate": {
            "sFirst": "Primero",
            "sPrevious": "Anterior",
            "sNext": "Siguiente",
            "sLast": "Último"
          }
        },
        "lengthMenu": [[-1], ["All"]],
        dom: 'Blftip',
        buttons: [
          $.extend( true, {}, buttonCommonExcel, {
            extend:'copyHtml5',
            title: titleTable,
            messageTop: function() {
              return `${subtitle}.
                      Fecha de exportación: ${datetime}
                      ${titleRangeMonths}`
            },
            exportOptions: { 
              columns: function(idx, data, node) {
                if ($.inArray(idx, columnsVisibleExcel) < 0) { return false; }
    
                return true;
              },
              format: {
                header: function ( text, index, node ) {
                  if ($("#bu-income-statement").length) {
                      subtitleMonth = $(node).find(".subtitle-month");
    
                      if(subtitleMonth.length){
                        return subtitleMonth.text();
                      }
    
                    return '';
                  }
    
                  return $(node).text().trim();
                }
              }
            }
          } ),
          $.extend( true, {}, buttonCommonExcel, {
              extend: 'excelHtml5',
              footer: true,
              customize: function ( xlsx ) {
                var sheet = xlsx.xl.worksheets['sheet1.xml'];
    
                $('row:eq(0) c', sheet).attr( 's', '50' );
                $('row:eq(1) c', sheet).attr( 's', '50' );
                $('row:eq(3) c', sheet).attr( 's', '2' );
                let flag = true;
                
                for (i = 0; i < $('row c[r^="B"]', sheet).length; i++) {
                  column = $('row c[r^="B"]', sheet)[i];
                  nameColumn = column.textContent
                  position = column.getAttribute('r')
  
                  if (categories.includes(nameColumn)) {
                    $('c[r="'+position+'"]', sheet).attr( 's', '2' );
                    
                    if (nameColumn == "Total") {
                      if (flag == true) {
                        let total_position = parseInt(position.substring(1)) + 2
                        $('c[r="D'+total_position+'"]', sheet).attr( 's', '2' );
                      }
  
                      flag = false;
                    }
                  }              
                }
  
                let lastRow = $('row', sheet).length;
                $('c[r="C' + lastRow + '"]', sheet).attr('s','2');
              },
              title: titleTable,
              exportOptions: {
                columns: function(idx, data, node) {
                  if ($.inArray(idx, columnsVisibleExcel) < 0) { return false; }
    
                  return true;
                },
              },
              messageTop: function() {
                return `${subtitle}.
                        Fecha de exportación: ${datetime}
                        ${titleRangeMonths}`
              },
          } ),
          $.extend( true, {}, buttonCommon, {
              extend: 'pdfHtml5',
              footer: true,
              orientation: 'landscape',
              pageSize: 'LEGAL',
              title: function() {
                return `${titleTable}`;
              },
              messageTop: function() {
                return `${subtitle}.
                        Fecha de exportación: ${datetime}
                        ${titleRangeMonths}`
              },
              exportOptions: {
                columns: function(idx, data, node) {
                  if ($.inArray(idx, columnsVisible) < 0) {
                    return false;
                  }
                  return true;
                },
                stripNewlines: false,
              },
              customize: function ( doc ) {
                doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};
                lengthRowsTable = doc.content[2].table.body.length;
    
                for (let i = 0; i <  doc.content[2].table.body[0].length; i++) {
                  doc.content[2].table.body[0][i].text = doc.content[2].table.body[0][i].text.replace("<br>","\n");
                }
                for (let i = 0; i < lengthRowsTable; i++) {
                  let column = 1;
    
                  if ( i == (lengthRowsTable - 1) && $("#ip-investor-report").length ) {
                    column = 2;
                  }
    
                  titleRow = doc.content[2].table.body[i][column].text;
                  titleRow = titleRow.toLowerCase();
    
                  switch (titleRow) {
                    case "saldo inicial":
                      styleRowDatatable(doc,i);
                      break;
                    case "saldo final":
                      styleRowDatatable(doc,i);
                      break;
                    case "total ingresos":
                      styleRowDatatable(doc,i);
                      break;
                    case "ingresos por venta":
                      styleRowDatatable(doc,i);
                      break;
                    case "costo integral financiero":
                      styleRowDatatable(doc,i);
                      break;
                    case "otros ingresos":
                      styleRowDatatable(doc,i);
                      break;
                    case "total egresos":
                      styleRowDatatable(doc,i);
                      break;
                    case "costos de venta":
                      styleRowDatatable(doc,i);
                      break;
                    case "costos de ventas":
                      styleRowDatatable(doc,i);
                      break;
                    case "costo de venta":
                      styleRowDatatable(doc,i);
                      break;
                    case "costos totales":
                      styleRowDatatable(doc,i);
                      break;
                    case "hardcost":
                      styleRowDatatable(doc,i);
                      break;
                    case "softcost":
                      styleRowDatatable(doc,i);
                      break;
                    case "costo integral financiero":
                      styleRowDatatable(doc,i);
                      break;
                    case "impuestos":
                      styleRowDatatable(doc,i);
                      break;
                    case "otros egresos":
                      styleRowDatatable(doc,i);
                      break;
                    case "valor del terreno":
                      styleRowDatatable(doc,i);
                      break;
                    case "total":
                      styleRowDatatable(doc,i);
                      break;
                    case "saldo proyecto":
                      styleRowDatatable(doc,i);
                      break;
                    case "utilidad antes de impuestos":
                      styleRowDatatable(doc,i);
                      break;
                    case "utilidad neta":
                      styleRowDatatable(doc,i);
                      break;
                    case "utilidad bruta":
                      styleRowDatatable(doc,i);
                      break;
                    case "gastos de operación":
                      styleRowDatatable(doc,i);
                      break;
                    case "costos financieros":
                      styleRowDatatable(doc,i);
                      break;
                    default:
                      break;
                  }
                }
    
                if ( img64 != '' ) {
                  doc.content.splice( 1, 0, {
                    margin: [ 0, 0, 0, 12 ],
                    alignment: 'left',
                    image: img64,
                    width: 170,
                    height: 30
                  } );
                }
              }
          } )
        ],
        aaSorting: [],
        ordering: false,
        searching: false,
      });
    });

    function fill_total(type, total) {
      let diference_value = total[2] - total[1]
      let percentage = 0
      if (total[2] != 0) {
        percentage = (100 * (total[1]/total[2])).toFixed(2)
      }
      $('#'+ type +'')[0].cells[2].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 italic data-value font-semibold data-value text-right">' + total[0].toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#'+ type +'')[0].cells[3].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 italic data-value font-semibold data-value text-right">' + total[1].toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#'+ type +'')[0].cells[4].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 italic data-value font-semibold data-value text-right">' + total[2].toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#'+ type +'')[0].cells[5].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 italic data-value font-semibold data-value text-right">' + diference_value.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</div>'
      '</td>';
      $('#'+ type +'')[0].cells[6].innerHTML = '<td class="border border-gray-200 text-center px-1 py-1 whitespace-nowrap">' +
        '<div class="text-sm text-gray-900 italic data-value font-semibold data-value text-right">' + percentage + '%</div>'
      '</td>';
    }

    $('.refresh-data').click(function () {
      location.reload(); 
    });
  }

  if ( $("#ip-cash-flow").length ) {
    projectName = $("#project-name").val();
    titleTable = "Flujo de efectivo";
    subtitle = `Proyecto inmobiliario: ${projectName}`;
    rangeMonths = $("#range-months").val();
    titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
    columnsFixed = 3;
  }

  $("#type-budget-report").on("change", function(){
    if($("#type-budget-report").val() != 0) {
      year = $("#actual-budget").val();
      month = $("#type-budget-report").val();
      month = parseInt(month);
      month = getMonthFilter(month);
      titleRangeMonths = `Rango de Fecha: ${month}/${year}`;
      columnsVisible = [0,1];
      if ($("#bu-income-statement").length) {
        columnsInitial = ($("#type-budget-report").val() * 4) - 2;
        columnsEnd = columnsInitial + 4;
      }else if($("#bu-cash-flow").length){
        columnsInitial = ($("#type-budget-report").val() * 1) + 2;
        columnsEnd = columnsInitial + 1;
      }else{
        columnsInitial = ($("#type-budget-report").val() * 1) + 1;
        columnsEnd = columnsInitial + 1;
      }

      for (let i = columnsInitial; i < columnsEnd; i++) {

        columnsVisible.push(i);
      }
      columnsLengthTable = 1;
    } else {
      columnsVisible = range(0,13);
      titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
      columnsLengthTable = tableIncomeStatementBU.columns().count();
    }
    columnsVisibleExcel = columnsVisible;
  })

  var tableIncomeStatementBU = $('#table-income-statement-report').DataTable({
    scrollY:        '68vh',
    scrollX:        true,
    scrollCollapse: true,
    paging:         false,
    fixedColumns:   {
      left: columnsFixed
    },
    responsive: true,
    "language": {
      "lengthMenu": "Mostrar _MENU_ registros por página",
      "zeroRecords": "Nada encontrado",
      "info": "Mostrando página _PAGE_ de _PAGES_",
      "infoEmpty": "No hay registros disponibles",
      "infoFiltered": "(filtrado de _MAX_ registros)",
      "sSearch": "Buscar:",
      "oPaginate": {
        "sFirst": "Primero",
        "sPrevious": "Anterior",
        "sNext": "Siguiente",
        "sLast": "Último"
      }
    },
    "lengthMenu": [[-1], ["All"]],
    dom: 'Blftip',
    buttons: [
      $.extend( true, {}, buttonCommonExcel, {
        extend:'copyHtml5',
        title: titleTable,
        messageTop: function() {
          return `${subtitle}.
                  Fecha de exportación: ${datetime}
                  ${titleRangeMonths}`
        },
        exportOptions: { 
          columns: function(idx, data, node) {
            if ($.inArray(idx, columnsVisibleExcel) < 0) { return false; }

            return true;
          },
          format: {
            header: function ( text, index, node ) {
              if ($("#bu-income-statement").length) {
                  subtitleMonth = $(node).find(".subtitle-month");

                  if(subtitleMonth.length){
                    return subtitleMonth.text();
                  }

                return '';
              }

              return $(node).text().trim();
            }
          }
        }
      } ),
      $.extend( true, {}, buttonCommonExcel, {
          extend: 'excelHtml5',
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:eq(0) c', sheet).attr( 's', '50' );
            $('row:eq(1) c', sheet).attr( 's', '50' );
            
            if($('#ip-investor-report').length){
              $('row:eq(3) c', sheet).attr( 's', '2' );
              let flag = true;
              
              for (i = 0; i < $('row c[r^="B"]', sheet).length; i++) {
                column = $('row c[r^="B"]', sheet)[i];
                nameColumn = column.textContent
                position = column.getAttribute('r')
                if (categories.includes(nameColumn)) {
                  $('c[r="'+ position +'"]', sheet).attr( 's', '2' );
                  
                  if (nameColumn == "Total") {
                    if (flag == true) {
                      let total_position = parseInt(position.substring(1)) + 2
                      $('c[r="D'+total_position+'"]', sheet).attr( 's', '2' );
                    }

                    flag = false;
                  }
                }              
              }

              let lastRow = $('row', sheet).length;
              $('c[r="C' + lastRow + '"]', sheet).attr('s','2');
            }

            if($('.bu-real-income-statement-page').length){
              var sSh = xlsx.xl['styles.xml'];
              var lastXfIndex = $('cellXfs xf', sSh).length - 1;
              var s1 = '<xf numFmtId="164" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>';
              sSh.childNodes[0].childNodes[5].innerHTML += s1;
              
              var boldCurrency = lastXfIndex + 1;
              var styledCells = [];
              var styledRows = [];
              let styledCategories = [];

              $('row c[r^="B"]', sheet).each(function() {
                var cellRef = $(this).attr('r');
                if (/^B[0-9]+$/.test(cellRef)) {
                  var nameColumn = $(this).text();
                  if (categories_bu.includes(nameColumn)) {
                    if (!styledCategories.includes(nameColumn)) {
                      $(this).attr('s', '2');
                      styledCells.push(cellRef);
                      styledCategories.push(nameColumn);
                      var rowNumber = parseInt(cellRef.replace(/\D/g, ''));
                      if (!styledRows.includes(rowNumber)) {
                          styledRows.push(rowNumber);
                      }
                    }
                  }
                }
              });

              styledRows.forEach(function(rowNumber) {
                $('row:eq(' + (rowNumber-1) + ') c', sheet).each(function() {
                  $(this).attr('s', boldCurrency);
                });
              });
            }

            if ($("#bu-income-statement").length) {
              var sSh = xlsx.xl['styles.xml'];
              var lastXfIndex = $('cellXfs xf', sSh).length - 1;
              var s1 = '<xf numFmtId="164" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>';
              var s2 = '<xf numFmtId="9" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>';
              sSh.childNodes[0].childNodes[5].innerHTML += s1 +s2;
              
              var boldCurrency = lastXfIndex + 1;
              var boldPercentage = lastXfIndex + 2;

              const NAME_ANUAL_COLUMN = "Anual";
              let actualBudget = $('#actual-budget').val();
              let numberMonthColumn = 1;
              let cut = 1
              $('row:eq(3) c', sheet).attr( 's', '2' );
              for (i = 0; i < $('row:eq(2) t', sheet).length; i++) {
                column = $('row:eq(2) t', sheet)[i];
                nameColumn = column.textContent
                if ( nameColumn.includes("Real") || nameColumn.includes("Diferencia") ) {
                  $('row:eq(2) t', sheet)[i].textContent = "";
                }
                if ( nameColumn.includes("Planeado") ) {
                  if ( nameColumn.includes(NAME_ANUAL_COLUMN) ) {
                    cutNameColumns(column, nameColumn, NAME_ANUAL_COLUMN, 5);
                  } else {
                    cutNameColumns(column, nameColumn, numberMonthColumn, cut);
                    numberMonthColumn++;

                    if ( numberMonthColumn == 10 ) cut = 2
                  }
                }
                if ( nameColumn.includes("Variación") ) {
                    cutNameColumns(column, nameColumn, actualBudget, 4);
                }
              }

              var styledCells = [];
              var styledRows = [];
              let styledCategories = [];

              var percentageColumns = ['E', 'I', 'M', 'Q', 'U', 'Y', 'AC', 'AG','AK','AO','AS','AW','BA']; //Columnas de Variación

              $('row c[r^="B"]', sheet).each(function() {
                var cellRef = $(this).attr('r');
                if (/^B[0-9]+$/.test(cellRef)) {
                  var nameColumn = $(this).text();
                  if (categories_bu.includes(nameColumn)) {
                    if (!styledCategories.includes(nameColumn)){
                      $(this).attr('s', '2');
                      styledCells.push(cellRef);
                      styledCategories.push(nameColumn)
                      var rowNumber = parseInt(cellRef.replace(/\D/g, ''));
                      if (!styledRows.includes(rowNumber)) {
                          styledRows.push(rowNumber);
                      }
                    }
                  }
                }
              });

              styledRows.forEach(function(rowNumber) {
                $('row:eq(' + (rowNumber-1) + ') c', sheet).each(function() {
                  var celda =  $(this).attr('r');
                  var soloColumna = celda.replace(/[0-9]/g, '');
                  if (percentageColumns.includes(soloColumna)) {
                    $(this).attr('s', boldPercentage);
                  }else{
                    $(this).attr('s', boldCurrency);
                  }
                  
                });
              });
            }

            if($('.bu-cash-flow-page').length){
              var sSh = xlsx.xl['styles.xml'];
              var lastXfIndex = $('cellXfs xf', sSh).length - 1;
              var s1 = '<xf numFmtId="164" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>';
              sSh.childNodes[0].childNodes[5].innerHTML += s1;
              
              var boldCurrency = lastXfIndex + 1;
              let styledCategories = [];
              
              for (i = 0; i < $('row c[r^="B"]', sheet).length; i++) {
                column = $('row c[r^="B"]', sheet)[i];
                nameColumn = column.textContent
                position = column.getAttribute('r');
                if (categories_bcf.includes(nameColumn)) {
                  if (!styledCategories.includes(nameColumn)){  
                    styledCategories.push(nameColumn)
                    $('row:eq('+ (i+3) +') c', sheet).attr( 's', boldCurrency);
                    $('c[r="'+ position +'"]', sheet).attr( 's', '2' );
                  }
                }              
              }
            }

            if($('.ip-cash-flow-page').length){
              var sSh = xlsx.xl['styles.xml'];
              var lastXfIndex = $('cellXfs xf', sSh).length - 1;
              var s1 = '<xf numFmtId="164" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>';
              sSh.childNodes[0].childNodes[5].innerHTML += s1;
              
              var boldCurrency = lastXfIndex + 1;
              
              for (i = 0; i < $('row c[r^="B"]', sheet).length; i++) {
                column = $('row c[r^="B"]', sheet)[i];
                nameColumn = column.textContent
                position = column.getAttribute('r');
                if (categories_ipcf.includes(nameColumn)) {
                  $('row:eq('+ (i+3) +') c', sheet).attr( 's', boldCurrency);
                  $('c[r="'+ position +'"]', sheet).attr( 's', '2' );
                }              
              }
            }
          },
          title: titleTable,
          exportOptions: {
            columns: function(idx, data, node) {
              if ($.inArray(idx, columnsVisibleExcel) < 0) { return false; }

              return true;
            },
          },
          messageTop: function() {
            return `${subtitle}.
                    Fecha de exportación: ${datetime}
                    ${titleRangeMonths}`
          },
      } ),
      $.extend( true, {}, buttonCommon, {
          extend: 'pdfHtml5',
          footer: true,
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: function() {
            return `${titleTable}`;
          },
          messageTop: function() {
            return `${subtitle}.
                    Fecha de exportación: ${datetime}
                    ${titleRangeMonths}`
          },
          exportOptions: {
            columns: function(idx, data, node) {
              if ($.inArray(idx, columnsVisible) < 0) {
                return false;
              }
              return true;
            },
            stripNewlines: false,
          },
          customize: function ( doc ) {
            doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};
            lengthRowsTable = doc.content[2].table.body.length;
            let styledCategories = [];

            const titles = [
              'saldo inicial',
              'saldo final',
              'total ingresos',
              'ingresos por venta',
              'costo integral financiero',
              'otros ingresos',
              'total egresos',
              'costos de venta',
              'costos de ventas',
              'costo de venta',
              'costos totales',
              'hardcost',
              'softcost',
              'costo integral financiero',
              'impuestos',
              'otros egresos',
              'valor del terreno',
              'total',
              'saldo proyecto',
              'utilidad antes de impuestos',
              'utilidad neta',
              'utilidad bruta',
              'gastos de operación',
              'costos financieros'
            ];

            const altTitles = [
              'total ingresos',
              'total egresos'
            ]

            for (let i = 0; i <  doc.content[2].table.body[0].length; i++) {
              doc.content[2].table.body[0][i].text = doc.content[2].table.body[0][i].text.replace("<br>","\n");
            }

            for (let i = 0; i < lengthRowsTable; i++) {
              let column = 1;

              if ( i == (lengthRowsTable - 1) && $("#ip-investor-report").length ) {
                column = 2;
              }

              titleRow = doc.content[2].table.body[i][column].text;
              titleRow = titleRow.toLowerCase();

              if (altTitles.includes(titleRow)){
                styleRowDatatable(doc, i);
              }else if (titles.includes(titleRow) && !styledCategories.includes(titleRow)){
                styledCategories.push(titleRow);
                styleRowDatatable(doc, i);
              }
            }

            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
      } )
    ],
    aaSorting: [],
    ordering: false,
    searching: false,
  });

  var  columnsLengthTable = tableIncomeStatementBU.columns().count();
  var columnsVisibleExcel = range(0,columnsLengthTable);

  $(".buttons-pdf").on("click", function () {
    let isExport = isMultipleExports(columnsLengthTable);

    if ( isExport ) {
      if( isOtherPage ) {
        numLaps = 1;
        if ( (columnsVisible[columnsVisible.length - 1] + 13) < columnsLengthTable) {
          columnsLength = columnsVisible[columnsVisible.length - 1] + 13;
          numFile++;
        } else {
          columnsLength = columnsVisible[columnsVisible.length - 1] + (columnsLengthTable - columnsVisible[columnsVisible.length -1])
          isOtherPage = false;
          numFile++;
        }
        columnInitial = columnsVisible[columnsVisible.length - 1] + 1;
        columnsVisible = [];
        for (let i = columnInitial; i < columnsLength; i++) {
          if (numLaps <= 1) {
            columnsVisible.push(0);
            columnsVisible.push(1);
            i -= 1;
            numLaps = 2;
          } else {
          columnsVisible.push(i);
          }
        }
        tableIncomeStatementBU.buttons('.buttons-pdf').trigger();
      } else {
        isOtherPage = true;
        columnsVisible = range(0,13);
        numFile = 1;
      }
    }
  });

  function isMultipleExports(columnsLengthTable) {
    let isExport = false;

    if ( !$("#ip-investor-report").length ) {
      let ipInvestorColumnsLimitExport = 12;
      let ipCashColumnsLimitExport = 6;

      if ( $("#ip-cash-flow").length && columnsLengthTable > ipInvestorColumnsLimitExport ) {
        isExport = true;
      }
      if ( !$("#ip-cash-flow").length && columnsLengthTable >= ipCashColumnsLimitExport ) {
        isExport = true;
      }
    }

    return isExport;
  }

  function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx);
  }

  function cutNameColumns(column, nameColumn, indexOfWord, cut) {
    cutPosition = nameColumn.indexOf(indexOfWord);
    column.textContent = nameColumn.substring(0, (cutPosition + cut))
  }

  function getMonthFilter(month) {
    switch (month) {
      case 1:
        month = "Enero";
        break;
      case 2:
        month = "Febrero";
        break;
      case 3:
        month = "Marzo";
        break;
      case 4:
        month = "Abril";
        break;
      case 5:
        month = "Mayo";
        break;
      case 6:
        month = "Junio";
        break;
      case 7:
        month = "Julio";
        break;
      case 8:
        month = "Agosto";
        break;
      case 9:
        month = "Septiembre";
        break;
      case 10:
        month = "Octubre";
        break;
      case 11:
        month = "Noviembre";
        break;
      case 12:
        month = "Diciembre";
        break;
      default:
        month = "Mes"
        break;
    }
    return month
  }

  function styleRowDatatable(doc, indexRow) {
    hashStyles = {fontSize: 11, bold: true}

    for (let j = 0; j <  doc.content[2].table.body[indexRow].length; j++) {
      doc.content[2].table.body[indexRow][j].style = hashStyles;
    }
  }

  var tableProjectReportIncomeExpense = null;
  var tableProjectFaseHours = null;

  function hideTables(){
    $('#planning-div').hide();
    $('#income-expense-div').hide();
    $('#hours-div').hide();
    $('#indirect-div').hide();
  }

  $("#planning-tab").on("click", function(){
    $('#charts-div').show();
    hideTables();

    $('#planning-div').show();
  })

  $('#profitability-income-tab').click((event) => {
    hideTables();
    let nameProject = $("#project-name").text();
    let businessUnitName = $("#business-unit-name").text().trim();
    let project_id = $('#project-name').val();
    let type_record = 'Ingresos';

    if ( tableProjectReportIncomeExpense != null) {
      tableProjectReportIncomeExpense.destroy();
      tableProjectReportIncomeExpense = null;
    }

    tableProjectReportIncomeExpense = $("#table-project-report-income-expense").DataTable({
      serverSide: true,
      scrollX: true,
      responsive: true,
      ajax: {
        url: '/records/rows',
        type: 'POST',
        data: {
          type_record: type_record,
          project_id: project_id,
          authenticity_token: AUTH_TOKEN
        },
        beforeSend: function(){
          $('#table-project-report-income-expense > tbody').html(
            '<tr class="odd">' +
              '<td valign="top" colspan="19" class="dataTables_empty">Cargando&hellip;</td>' +
            '</tr>'
          );
        },
        dataSrc: function(json){
          return json.data;
        },
      },
      columnDefs: [
        { className: "px-1 py-1 border border-gray-200 text-center whitespace-nowrap text-xs font-medium", "targets": "_all"},
      ],
      columns: [
        { data: "showed_id" },
        { data: 'date' },
        { data: 'project_r',
          render: function ( data, type, row, meta ) {
            if ( data == "oficina" ) {
              const lower = data.toLowerCase();
              dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);

              return dataCapitalize
            } else {
              return data
            }
          }
        },
        { data: 'category_r',
          render: function ( data, type, row, meta ) {
            switch ( data ) {
              case "Costos de venta":
                data = "Costo de venta";
                break;
              case "Costos financieros":
                data = "Costo integral financiero";
                break;
              default:
                break;
            }

            return data
          }
        },
        { data: 'subcategory_r',
          render: function ( data, type, row, meta ) {
            if ( data == "preventas" || data == "saldos" || data == "comisiones sobre venta" || data == "rentas garantizadas" ) {
              const lower = data.toLowerCase();
              dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);
              
              return dataCapitalize
            } else {
              return data
            }
          }
        },
        { data: 'concept_r' },
        { data: 'account_r' },
        { data: 'total_amount_r',
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: 'value_iva_r',
          render: function ( data, type, row, meta ) {
          if (data[2]) {
            switch (data[3]) {
              case "%":
                amount = (data[0] / (1 + (data[1] / 100)))*(data[1] / 100);
                amount = String(amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                amount = amount + " ("+data[1]+"%)";
                return amount
                break;
              case "$":
                percentage = (((data[0]/(data[0] - data[1])) - 1)) * 100;
                percentage = String(percentage.toFixed(2));
                amount = String(data[1].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                amount = amount;
                return amount+ " ("+percentage+"%)";
                break;
            }
          } else {
            return "$0.00 (0%)";
          }
        }},
        { data: 'subtotal',
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
      ],
      language: {
        lengthMenu: "Mostrar _MENU_ registros por página",
        zeroRecords: "Nada encontrado",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay registros disponibles",
        infoFiltered: "(filtrado de _MAX_ registros)",
        oPaginate: {
          sFirst: "Primero",
          sPrevious: "Anterior",
          sNext: "Siguiente",
          sLast: "Último",
        },
      },
      "order": [[ 1, 'asc' ]],
      lengthMenu: [
        [10, 25, 50, 100, 500, 1000],
        [10, 25, 50, 100, 500, 1000],
      ],
      dom: "Bltip",
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Ingresos del proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend:'excelHtml5',
          title: `Ingresos: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend: 'pdfHtml5',
          title: `Ingresos: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
          exportOptions:{
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(6).footer()).html(
          'Total: '
        )
        $(api.column(7).footer()).html(
          sum(api.column(7, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
        )
        $(api.column(9).footer()).html(
          sum(api.column(9, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
        )

      }
    });

    hideTablesProfitability();
    $('#profitability-income-expense-div').show();
  });

  function hideTablesProfitability(){
    $('#profitability-planning-div').hide();
    $('#profitability-income-expense-div').hide();
    $('#profitability-hours-div').hide();
    $('#profitability-indirect-div').hide();
    $('#profitability-phase-hours-div').hide();
    $('#profitability-architect-hours-div').hide();
  }

  //INGRESOS

  //EGRESOS
  $("#profitability-hours-tab").on("click", function(){
    hideTablesProfitability();
    $('#profitability-hours-div').show();
  })

  if ( $("#table-project-report-indirect2").length ) {
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    var tableProjectReportIndirect2 = $("#table-project-report-indirect2").DataTable({
      scrollCollapse: true,
      scrollY: '40vh',
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "order": [[ 0, 'decs' ]],
      dom: 'Blftip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Indirectos del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
        },
        {
          extend:'excelHtml5',
          title: `Indirectos del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Indirectos del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions:{
            columns: [0, 1, 2, 3],
            rows: ':visible'
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        columnsWithSum = [2, 3];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });
  }

  $("#profitability-indirect-tab").on("click", function(){
    let project_id = $('#project-name').val();
    let type_report = 'indirect'

    tableProjectReportIndirect2.destroy();

    $("#table-project-report-indirect2-body").empty();

    hideTablesProfitability();

    $.ajax({
      url: "/business_units/get_handwork",
      method: "GET",
      dataType: "json",
      data: {project_id, type_report},
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        datares = response["info_indirect_factor"];

        fillIndirectTable(datares);
        $('#profitability-indirect-div').show(); 
      },
      complete: function (response) {
        reinitializeIndirect();
      }
    });
  });

  function reinitializeIndirect(){
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    tableProjectReportIndirect2 = $("#table-project-report-indirect2").DataTable({
      scrollCollapse: true,
      scrollY: '40vh',
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "order": [[ 0, 'decs' ]],
      dom: 'Blftip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Indirectos del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
        },
        {
          extend:'excelHtml5',
          title: `Indirectos del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Indirectos del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: {
            columns: [0, 1, 2, 3],
            rows: ':visible'
          },
          footer: true,
          customize: function(doc) {
            doc.content.forEach(function(item) {
              if (item.table && item.table.body) {
                item.table.body.forEach(function(row) {
                  row.forEach(function(cell, j) {
                    cell.alignment = 'center';
                  });
                });
              }
            });
            if (img64 != '') {
              doc.content.splice(1, 0, {
                margin: [0, 0, 0, 12],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              });
            }
          }
        }
      ],
      "drawCallback":function(){
        columnsWithSum = [2, 3];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });
    
  }

  function fillIndirectTable(data){
    let indirects = 0;
    var tableIndirect = document.getElementById('table-project-report-indirect2-body');
    Object.keys(data).forEach(function(object) {
      indirects = data[object]["Cost"] * (data[object]["Indirect Factor"]/100);
      var tr = document.createElement('tr');
      tr.className = "select-none cursor-pointer";
        tr.innerHTML = '<td class="text-sm text-gray-900 font-semibold whitespace-nowrap pr-10">' + data[object]["Initial Date"] +'</td>' +
        '<td class="text-sm text-gray-900 font-semibold text-center">' + data[object]["Indirect Factor"] + '%'+ '</td>' +
        '<td class="text-sm text-gray-900 font-semibold px-2">' + data[object]["Cost"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
        '<td class="text-sm text-gray-900 font-semibold px-2">' + indirects.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>';
        tableIndirect.appendChild(tr);
    });
  }

  //FASE

  $("#profitability-phase-hours-tab").on("click", function(){
    let project_id = $('#project-name').val();
    let type_report = "phases";
    
    tableProjectPhaseHours2.destroy();
    $("#report-project-phase-hours2-body").empty();

    hideTablesProfitability();

    $.ajax({
      url: "/business_units/get_handwork",
      method: "GET",
      dataType: "json",
      data: {project_id, type_report},
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        datares = {
          "phase_hours_data" : response["info_phases_hours"],
          "total_hours" : minutes_to_hours(response["total_hours"])
        }

        fillPhaseHoursTable(datares);
        $('#profitability-phase-hours-div').css({
          'display': 'flex',
          'visibility': 'visible'
        });  
      },
      complete: function (response) {
        reinitializePhaseHoursTable();
      }
    });
  });

  if ( $("#report-project-phase-hours2").length ) {
    let nameProject = $("#project-name").text();

    tableProjectPhaseHours2 = $('#report-project-phase-hours2').DataTable({
      scrollCollapse: true,
      scrollY: '38vh',
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Bltip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Horas por Fase Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
        },
        {
          extend:'excelHtml5',
          title: `Horas por Fase Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Horas por Fase Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );

        $(api.column(2).footer()).html(
          sum(api.column(2, {page:'current'}).data()).toFixed(2)
        );
      }
    });
  }

  function reinitializePhaseHoursTable(){

    nameProject = $("#project-name").text();

    tableProjectPhaseHours2 = $('#report-project-phase-hours2').DataTable({
      scrollCollapse: true,
      scrollY: '38vh',
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Bltip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Horas por Fase Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
        },
        {
          extend:'excelHtml5',
          title: `Horas por Fase Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Horas por Fase Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );

        $(api.column(2).footer()).html(
          sum(api.column(2, {page:'current'}).data()).toFixed(2)
        );
      }
    });
  }

  function convertHoursToDecimal(time) {
    if (time.includes(':')) {
      const [hours, minutes] = time.split(':').map(parseFloat);
      const decimalHours = hours + minutes / 60;
      return parseFloat(decimalHours.toFixed(2));
    }
  }

  function minutes_to_hours(total_minutes) {
    hours = Math.floor(total_minutes/60);
    minutes = total_minutes - (hours*60);

    if (minutes < 10) {
      minutes = '0' + String(minutes);
    }

    return total_time = String(hours) + ':' + String(minutes);
  }

  //ARQUITECTO

  $("#profitability-architect-hours-tab").on("click", function(){
    let project_id = $('#project-name').val();
    let type_report = "workers";
    
    tableProjectArchitectHours.destroy();
    $("#report-project-architect-hours2-body").empty();

    hideTablesProfitability();

    $.ajax({
      url: "/business_units/get_handwork",
      method: "GET",
      dataType: "json",
      data: {project_id, type_report},
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        datares = {
          "architect_hours_data" : response["info_team_work"],
          "total_hours" : minutes_to_hours(response["total_hours"])
        }

        fillArchitectHoursTable(datares);
        $('#profitability-architect-hours-div').css({
          'display': 'flex',
          'visibility': 'visible'
        });
      },
      complete: function (response) {
        reinitializeArchitectHoursTable();
      }
    });
  });

  if ( $("#report-project-architect-hours2").length ) {
    let nameProject = $("#project-name").text();

    tableProjectArchitectHours = $('#report-project-architect-hours2').DataTable({
      scrollCollapse: true,
      scrollY: '40vh',
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Bltip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Horas por Arquitecto Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
        },
        {
          extend:'excelHtml5',
          title: `Horas por Arquitecto Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Horas por Arquitecto Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );

        $(api.column(2).footer()).html(
          sum(api.column(2, {page:'current'}).data()).toFixed(2)
        );
      }
    });
  }

  function reinitializeArchitectHoursTable(){
    nameProject = $("#project-name").text();

    tableProjectArchitectHours = $('#report-project-architect-hours2').DataTable({
      scrollCollapse: true,
      scrollY: '40vh',
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Bltip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Horas por Arquitecto Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
        },
        {
          extend:'excelHtml5',
          title: `Horas por Arquitecto Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Horas por Arquitecto Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 2],
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        );
        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );

        $(api.column(2).footer()).html(
          sum(api.column(2, {page:'current'}).data()).toFixed(2)
        );
      }
    });
  }

  function fillArchitectHoursTable(data) {
    const labels = [];
    const hoursData = [];
  
    Object.keys(data.architect_hours_data).forEach(function (object) {
      const phaseName = object; 
      const minutes = data.architect_hours_data[object]["Minutes"];
      const hours = convertHoursToDecimal(minutes_to_hours(minutes));
  
      labels.push(phaseName);
      hoursData.push(hours);
  
      $("#report-project-architect-hours2-body").append(`
        <tr>
          <td class="border border-gray-200 text-center px-1 py-1">
            <div class="text-sm text-gray-900 font-semibold whitespace-nowrap">${phaseName}</div>
          </td>
          <td class="border border-gray-200 text-center px-1 py-1 text-sm text-gray-900 font-semibold">
            ${minutes_to_hours(minutes)}
          </td>
          <td class="hidden border border-gray-200 text-center px-1 py-1 text-sm text-gray-900 font-semibold">
            ${hours}
          </td>
        </tr>
      `);
    });
  
    $("#total-architect-hours").text(data.total_hours);
    initializeArchitectHoursChart(labels, hoursData);
  }
  
  //RENTABILIDAD

  $("#profitability-planning-tab").on("click", function(){
    
    let project_id = $('#project-name').val();
    let business_unit_id = $('#business-unit-id').text();
    let checked_iva = $('#iva-profitability').is(":checked");

    tableProjectReport.destroy();
    $("#profitability-table-body").empty();

    hideTablesProfitability();

    $.ajax({ 
      url: "/business_units/update_project_profitability",
      method: "GET",
      dataType: "json",
      data: {project_id,
        business_unit_id,
        checked_iva},
      error: function (xhr, status, error) {      
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        datares = {
          "values" : response["values"],
          "sale_income_subcategories" : response["subcategories_income"],
          "sale_cost_subcategories" : response["subcategories_expense"],
          "other_incomes_subcategories" : response["other_incomes_subcategories"],
          "other_expenses_subcategories" : response["other_expenses_subcategories"],
          "budget_exists" : true,
          "budget_type": 1
        }
        //DEVOLVER SI EXISTE PRESUPUESTO Y EL TIPO DE PRESUPUESTO (DESGLOSADO MONTO / DESGLOSADO PORCENTAJE)

        fillProfitabilityTable(datares);
        updateSaleCostGraph(getTopSubcategories(datares));
        updateProfitabilityCompositionGraph(datares);
        //$('#profitability-planning-div').show();
      },
      complete: function (response) {
        reinitializeProfitabilityTable();
        subcategoriesTitleLimit();
        $('.tab-content-container').removeClass('justify-start');
        $('.tab-content-container').addClass('justify-around');
        $('#profitability-planning-div').show();
      }
    });
  });

  $('#iva-profitability:checkbox').change(function(){
    $("#profitability-planning-tab").click();
  });

  if($('#profitability-table').length) {
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    tableProjectReport = $("#profitability-table").DataTable({
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "bLengthChange" : false,
      "bPaginate": false,
      dom: 'Blftp',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime} \n`,
        },
        {
          extend: 'excelHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime} \n`,
          exportOptions: {
            columns: [1, 2, 3, 4],
            rows: ':visible'
          },
          customizeData: function (excelData) {
            var data = excelData.body;

            profitability_table_rows = [];
            
            var boldTitles = [
              'Ingresos por venta',
              'Costo de venta',
              'Utilidad bruta',
              'Utilidad neta',
              'Otros Ingresos',
              'Otros Egresos',
              'Saldo'
            ];
          
            for (var i = 0; i < data.length; i++) {
              var firstCell = data[i][0];
          
              if (boldTitles.includes(firstCell)) {
                profitability_table_rows.push(i+3);
                for (var j = 0; j < data[i].length; j++) {
                  excelData.body[i][j].style = { font: { bold: true } };
                }
              }
            }
            return excelData;
          },
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
            var sSh = xlsx.xl['styles.xml'];
            var lastXfIndex = $('cellXfs xf', sSh).length - 1;
            var s1 = '<xf numFmtId="164" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>';
            sSh.childNodes[0].childNodes[5].innerHTML += s1;
            var boldCurrency = lastXfIndex + 1;

            var percentageStyle = `<xf numFmtId="9" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>`;
            sSh.childNodes[0].childNodes[5].innerHTML += percentageStyle;
            var percentageFormat = lastXfIndex + 2;

            for (let i = 0; i < profitability_table_rows.length; i++) {
              $('row:eq('+ profitability_table_rows[i] +') c:eq(0)', sheet).attr( 's', '2' );
              $('row:eq('+ profitability_table_rows[i] +') c:eq(1)', sheet).attr( 's', boldCurrency );
              $('row:eq('+ profitability_table_rows[i] +') c:eq(2)', sheet).attr( 's', boldCurrency );
              $('row:eq('+ profitability_table_rows[i] +') c:eq(3)', sheet).attr( 's', percentageFormat );
            }
          },
        },
        {
          extend: 'pdfHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime} `,
          exportOptions:{
            columns: [1, 2, 3, 4],
            rows: ':visible'
          },
          customize: function ( doc ) {
              doc.content[0].style = {bold: true, alignment: "center", fontSize: "20"};
              lengthRowsTable = doc.content[2].table.body.length;

              for (let i = 0; i < lengthRowsTable; i++) {
                let column = 0;

                titleRow = doc.content[2].table.body[i][column].text;
                titleRow = titleRow.toLowerCase();
                switch (titleRow) {
                  case "ingresos por venta":
                    styleRowDatatable(doc,i);
                    break;
                  case "costo de venta":
                    styleRowDatatable(doc,i);
                    break;
                  case "utilidad bruta":
                    styleRowDatatable(doc,i);
                    break;
                  case "utilidad neta":
                    styleRowDatatable(doc,i);
                    break;
                  case "otros ingresos":
                    styleRowDatatable(doc,i);
                    break;
                  case "otros egresos":
                    styleRowDatatable(doc,i);
                    break;
                  case "saldo":
                    styleRowDatatable(doc,i);
                    break;
                  default:
                    break;
                }
              }

              if ( img64 != '' ) {
                doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
                } );
              }
            }
        }
      ],
      aaSorting: [],
      ordering: false,
      searching: false,
    });
  }

  function reinitializeProfitabilityTable(){
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    tableProjectReport = $("#profitability-table").DataTable({
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "bLengthChange" : false,
      "bPaginate": false,
      dom: 'Blftp',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime} \n`,
        },
        {
          extend: 'excelHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime} \n`,
          exportOptions: {
            columns: [1, 2, 3, 4],
            rows: ':visible'
          },
          customizeData: function (excelData) {
            var data = excelData.body;

            profitability_table_rows = [];
            let styledCategories = [];

            var boldTitles = [
              'Ingresos por venta',
              'Costo de venta',
              'Utilidad bruta',
              'Utilidad neta',
              'Otros Ingresos',
              'Otros Egresos',
              'Saldo'
            ];
          
            for (var i = 0; i < data.length; i++) {
              var firstCell = data[i][0];
          
              if (boldTitles.includes(firstCell) && !styledCategories.includes(firstCell)) {
                styledCategories.push(firstCell);
                profitability_table_rows.push(i+3);
                for (var j = 0; j < data[i].length; j++) {
                  excelData.body[i][j].style = { font: { bold: true } };
                }
              }
            }
            return excelData;
          },
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
            var sSh = xlsx.xl['styles.xml'];
            var lastXfIndex = $('cellXfs xf', sSh).length - 1;
            var s1 = '<xf numFmtId="164" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>';
            sSh.childNodes[0].childNodes[5].innerHTML += s1;
            var boldCurrency = lastXfIndex + 1;

            var percentageStyle = `<xf numFmtId="9" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1"></xf>`;
            sSh.childNodes[0].childNodes[5].innerHTML += percentageStyle;
            var percentageFormat = lastXfIndex + 2;

            for (let i = 0; i < profitability_table_rows.length; i++) {
              $('row:eq('+ profitability_table_rows[i] +') c:eq(0)', sheet).attr( 's', '2' );
              $('row:eq('+ profitability_table_rows[i] +') c:eq(1)', sheet).attr( 's', boldCurrency );
              $('row:eq('+ profitability_table_rows[i] +') c:eq(2)', sheet).attr( 's', boldCurrency );
              $('row:eq('+ profitability_table_rows[i] +') c:eq(3)', sheet).attr( 's', percentageFormat );
            }
          },
        },
        {
          extend: 'pdfHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime} `,
          exportOptions:{
            columns: [1, 2, 3, 4],
            rows: ':visible'
          },
          customize: function (doc) {
            doc.content[0].style = { bold: true, alignment: "center", fontSize: "20" };
            lengthRowsTable = doc.content[2].table.body.length;
            let styledCategories = [];
            const titles = [
              'ingresos por venta',
              'costo de venta',
              'utilidad bruta',
              'utilidad neta',
              'otros ingresos',
              'otros egresos',
              'saldo'
            ];
          
            for (let i = 0; i < lengthRowsTable; i++) {
              let column = 0;
              let titleRow = doc.content[2].table.body[i][column].text;
              titleRow = titleRow.toLowerCase();
          
              if (titles.includes(titleRow) && !styledCategories.includes(titleRow)) {
                styledCategories.push(titleRow);
                styleRowDatatable(doc, i);
              }
            }

            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              });
            }
          }
        }
      ],
      aaSorting: [],
      ordering: false,
      searching: false,
    });
  }

  //FUNCIONES RENTABILIDAD
  function fillProfitabilityTable(data){

    //INGRESOS POR VENTA
    let table_body = document.getElementById('profitability-table-body');
    table_body.innerHTML = "";
    var tr = document.createElement('tr');
    tr.className = "expand-1-1";
    tr.innerHTML = '<td class="px-2 cursor-pointer">' +
      '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">' +
        '<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />'+
      '</svg>'+
    '</td>'+
    '<td class="py-4 font-bold whitespace-nowrap px-2">Ingresos por venta</td>'+
    '<td id="sale-income-real" class="px-2 text-right">' + data["values"]["sale_income_real"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td id="sale-income-budget" class="px-2 text-center">' + data["values"]["sale_income_budget"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td class="px-2">'+
      '<div class="progress">'+
        '<div id="pb-sale-income" class="progress-bar data-value">'+
        '</div>'+
      '</div>'+
      '<div id="pb-sale-income-value" class="text-sm text-gray-900 font-semibold text-center data-value"></div>'+
    '</td>';
    table_body.appendChild(tr);
    
    Object.keys(data.sale_income_subcategories).forEach(function(object) {
      var tr = document.createElement('tr');
      tr.className = "expand-1-1-1 select-none";
        tr.innerHTML = '<td></td>' + 
        '<td class="py-4 font-semibold text-left px-2 profitability-subcategory whitespace-nowrap">' + object + '</td>' +
        '<td id="" class="px-2 text-right">' + data.sale_income_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
        '<td></td>' +
        '<td class=""></td>';
        table_body.appendChild(tr);
        
    });

    //COSTO DE VENTA
    var tr = document.createElement('tr');
    tr.className = "expand-1-2";
    tr.innerHTML = '<td class="px-2 cursor-pointer">' +
      '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">' +
        '<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />'+
      '</svg>'+
    '</td>'+
    '<td class="py-4 font-bold whitespace-nowrap px-2">Costo de venta</td>'+
    '<td id="sale-cost-real" class="px-2 text-right">' + data["values"]["sale_cost_real"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td id="sale-cost-budget" class="px-2 text-center">' + data["values"]["sale_cost_budget"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td class="px-2">'+
      '<div class="progress">'+
        '<div id="pb-sale-cost" class="progress-bar data-value">'+
        '</div>'+
      '</div>'+
      '<div id="pb-sale-cost-value" class="text-sm text-gray-900 font-semibold text-center data-value"></div>'+
    '</td>';
    table_body.appendChild(tr);

    //SIN PRESUPUESTO Y SOLO INGRESOS
    if(data["values"]["type_ppt"]==null){
      Object.keys(data.sale_cost_subcategories).forEach(function(object) {
        var tr = document.createElement('tr');
        tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
          tr.innerHTML = '<td></td>' + 
          '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
          '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
          '<td class="text-center"></td>' +
          '<td class="px-2">'+
           
          '</td>';
        table_body.appendChild(tr);
      });
    }else if(data["values"]["type_ppt"]==2){
      Object.keys(data.sale_cost_subcategories).forEach(function(object) {
  
        var tr = document.createElement('tr');
        tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
          tr.innerHTML = '<td></td>' + 
          '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
          '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
          '<td class="text-center"></td>' +
          '<td class="px-2">'+
           
          '</td>';
        table_body.appendChild(tr);
        
      });
    }

    //COSTO DE VENTA DESGLOSADO (COTIZADOR E INGRESOS Y EGRESOS)
    if(data['values']["budget_type"]==1 && data['values']["budget_category"]==1){
      Object.keys(data.sale_cost_subcategories).forEach(function(object) {
        let budget = data.sale_cost_subcategories[object]['Planning'];
        let real_value = data.sale_cost_subcategories[object]['Real'];
        let sale_cost_percentage;
        let value;
        let bar_color;
        
        if (budget != 0) {
          sale_cost_percentage = Math.floor(real_value/budget.toFixed(2)*100);
        }else{
          sale_cost_percentage = 0;
        }
  
        value = sale_cost_percentage;
  
        if(sale_cost_percentage>100){
          bar_color = "progress-bar-red";
          sale_cost_percentage = 100;
        }else{
          bar_color = "progress-bar-green";
        }
  
        if((data["values"]["type_ppt"]!=null) && (budget == 0)){
          var tr = document.createElement('tr');
          tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
            tr.innerHTML = '<td></td>' + 
            '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
            '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
            '<td class="text-center">' + budget.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</td>' +
            '<td class="px-2">'+
              '<div class="progress">'+
                '<div id="" class="progress-bar data-value ' + bar_color + '" style="width: '+ sale_cost_percentage +'%">'+
                '</div>'+
              '</div>'+
              '<div id="" class="text-sm text-red-700 font-semibold text-center data-value">! ' + value + '%</div>'+
            '</td>';
          table_body.appendChild(tr);
        }else{
          var tr = document.createElement('tr');
          tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
            tr.innerHTML = '<td></td>' + 
            '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
            '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
            '<td class="text-center">' + budget.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</td>' +
            '<td class="px-2">'+
              '<div class="progress">'+
                '<div id="" class="progress-bar data-value ' + bar_color + '" style="width: '+ sale_cost_percentage +'%">'+
                '</div>'+
              '</div>'+
              '<div id="" class="text-sm text-gray-900 font-semibold text-center data-value">' + value + '%</div>'+
            '</td>';
          table_body.appendChild(tr);
        }
      });
    }

    //COSTO DE VENTA FIJO (COTIZADOR E INGRESOS Y EGRESOS)
    if(data['values']["budget_type"]==1 && data['values']["budget_category"]==0){
      Object.keys(data.sale_cost_subcategories).forEach(function(object) {
        let budget = data.sale_cost_subcategories[object]['Planning'];
  
        var tr = document.createElement('tr');
        tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
          tr.innerHTML = '<td></td>' + 
          '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
          '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
          '<td class="text-center">' + budget.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</td>' +
          '<td class="px-2">'+
           
          '</td>';
        table_body.appendChild(tr);
    
      });
    }
    
    //COSTO DE VENTA DESGLOSADO PORCENTAJE (COTIZADOR E INGRESOS Y EGRESOS)
    if(data['values']["budget_type"]==0 && data['values']["budget_category"]==1){
      Object.keys(data.sale_cost_subcategories).forEach(function(object) {

        let sale_income_budget = data["values"]["sale_income_budget"];
        
        let budget = data.sale_cost_subcategories[object]['Planning']; //%

        let subcategory_budget = sale_income_budget*(budget*0.01);

        let real_value = data.sale_cost_subcategories[object]['Real'];
        let sale_cost_percentage;
        let value;
        let bar_color;
        
        if (budget != 0) {
          sale_cost_percentage = Math.floor(real_value/subcategory_budget.toFixed(2)*100);
        }else{
          sale_cost_percentage = 0;
        }
  
        value = sale_cost_percentage;
  
        if(sale_cost_percentage>100){
          bar_color = "progress-bar-red";
          sale_cost_percentage = 100;
        }else{
          bar_color = "progress-bar-green";
        }
  
        if((data["values"]["type_ppt"]!=null) && (budget == 0)){
          var tr = document.createElement('tr');
          tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
            tr.innerHTML = '<td></td>' + 
            '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
            '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
            '<td class="text-center">' + subcategory_budget.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</td>' +
            '<td class="px-2">'+
              '<div class="progress">'+
                '<div id="" class="progress-bar data-value ' + bar_color + '" style="width: '+ sale_cost_percentage +'%">'+
                '</div>'+
              '</div>'+
              '<div id="" class="text-sm text-red-700 font-semibold text-center data-value">! ' + value + '%</div>'+
            '</td>';
          table_body.appendChild(tr);
        }else{
          var tr = document.createElement('tr');
          tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
            tr.innerHTML = '<td></td>' + 
            '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
            '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
            '<td class="text-center">' + subcategory_budget.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</td>' +
            '<td class="px-2">'+
              '<div class="progress">'+
                '<div id="" class="progress-bar data-value ' + bar_color + '" style="width: '+ sale_cost_percentage +'%">'+
                '</div>'+
              '</div>'+
              '<div id="" class="text-sm text-gray-900 font-semibold text-center data-value">' + value + '%</div>'+
            '</td>';
          table_body.appendChild(tr);
        }
      });
      
    }

    //COSTO DE VENTA FIJO PORCENTAJE (COTIZADOR E INGRESOS Y EGRESOS)
    if(data['values']["budget_type"]==0 && data['values']["budget_category"]==0){
      Object.keys(data.sale_cost_subcategories).forEach(function(object) {

        let sale_income_budget = data["values"]["sale_income_budget"];
        let budget = data.sale_cost_subcategories[object]['Planning']; //%
        let subcategory_budget = sale_income_budget*(budget*0.01);

  
        var tr = document.createElement('tr');
        tr.className = "expand-1-2-1 select-none sale-cost-subcategory";
          tr.innerHTML = '<td></td>' + 
          '<td class="py-4 font-semibold text-right profitability-subcategory  whitespace-nowrap">' + object + '</td>' +
          '<td id="" class="px-2 text-right">' + data.sale_cost_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
          '<td class="text-center">' + subcategory_budget.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</td>' +
          '<td class="px-2">'+
            
          '</td>';
        table_body.appendChild(tr);
        
      });
      
    }

    //UTILIDAD BRUTA
    var tr = document.createElement('tr');
    tr.innerHTML = '<td class="px-2 cursor-pointer"></td>'+
    '<td class="py-4 font-bold whitespace-nowrap px-2">Utilidad bruta</td>'+
    '<td id="brute-utility-real" class="px-2 text-right font-bold">' + data["values"]["brute_utility_real"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td id="brute-utility-budget" class="px-2 text-center font-bold">' + data["values"]["brute_utility_budget"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td class="px-2">'+
      '<div class="progress">'+
        '<div id="pb-brute-utility" class="progress-bar data-value">'+
        '</div>'+
      '</div>'+
      '<div id="pb-brute-utility-value" class="text-sm text-gray-900 font-semibold text-center data-value"></div>'+
    '</td>';
    table_body.appendChild(tr);
    
    //MANO DE OBRA
    var tr = document.createElement('tr');
    tr.innerHTML = '<td class="px-2 cursor-pointer"></td>'+
    '<td id="" class="py-4 font-semibold whitespace-nowrap show-project-handwork">'+
        '<span class="border border-gray-200 shadow-lg rounded cursor-pointer py-1 px-2 bg-white focus:outline-none">Mano de obra</span>'+
    '</td>'+
    '<td id="handwork-real" class="px-2 text-right">' + data["values"]["handwork_real"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td id="handwork-budget" class="px-2 text-center">' + data["values"]["handwork_budget"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td class="px-2">'+
      '<div class="progress">'+
        '<div id="pb-handwork" class="progress-bar data-value">'+
        '</div>'+
      '</div>'+
      '<div id="pb-handwork-value" class="text-sm text-gray-900 font-semibold text-center data-value"></div>'+
    '</td>';
    table_body.appendChild(tr);

    //INDIRECTOS DE OFICINA
    var tr = document.createElement('tr');
    tr.innerHTML = '<td class="px-2 cursor-pointer"></td>'+
    '<td class="py-4 font-semibold whitespace-nowrap px-2">Indirectos de Oficina</td>'+
    '<td id="office-indirects-real" class="px-2 text-right">' + data["values"]["office_indirects_real"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td id="office-indirects-budget" class="px-2 text-center">' + data["values"]["office_indirects_budget"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td class="px-2">'+
      '<div class="progress">'+
        '<div id="pb-office-indirects" class="progress-bar data-value">'+
        '</div>'+
      '</div>'+
      '<div id="pb-office-indirects-value" class="text-sm text-gray-900 font-semibold text-center data-value"></div>'+
    '</td>';
    table_body.appendChild(tr);

    //UTILIDAD NETA
    var tr = document.createElement('tr');
    tr.innerHTML = '<td class="px-2 cursor-pointer"></td>'+
    '<td class="py-4 font-bold whitespace-nowrap px-2">Utilidad neta</td>'+
    '<td id="net-utility-real" class="px-2 text-right font-bold">' + data["values"]["net_utility_real"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td id="net-utility-budget" class="px-2 text-center font-bold">' + data["values"]["net_utility_budget"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td class="px-2">'+
      '<div class="progress">'+
        '<div id="pb-net-utility" class="progress-bar data-value">'+
        '</div>'+
      '</div>'+
      '<div id="pb-net-utility-value" class="text-sm text-gray-900 font-semibold text-center data-value"></div>'+
    '</td>';
    table_body.appendChild(tr);

    //ESPACIO
    var tr = document.createElement('tr');
    tr.className = "";
    tr.innerHTML = '<td class="px-2 cursor-pointer"></td>'+
    '<td class="font-semibold whitespace-nowrap px-2 h-4"></td>'+
    '<td id="" class="px-2 text-right"></td>'+
    '<td></td>'+
    '<td class="px-2"></td>';
    table_body.appendChild(tr);

    //OTROS INGRESOS
    var tr = document.createElement('tr');
    tr.className = "expand-1-3";
    tr.innerHTML = '<td class="px-2 cursor-pointer">' +
      '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">' +
        '<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />'+
      '</svg>'+
    '</td>'+
    '<td class="py-4 font-semibold whitespace-nowrap px-2">Otros Ingresos</td>'+
    '<td id="other-incomes" class="px-2 text-right">' + data["values"]["other_incomes"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td></td>'+
    '<td class="px-2"></td>';
    table_body.appendChild(tr);

    Object.keys(data.other_incomes_subcategories).forEach(function(object) {
      var tr = document.createElement('tr');
      tr.className = "expand-1-3-1 select-none";
        tr.innerHTML = '<td></td>' + 
        '<td class="py-4 font-semibold text-right profitability-subcategory whitespace-nowrap">' + object + '</td>' +
        '<td id="" class="px-2 text-right">' + data.other_incomes_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
        '<td></td>' +
        '<td class=""></td>';
        table_body.appendChild(tr);
        
    });

    //OTROS EGRESOS
    var tr = document.createElement('tr');
    tr.className = "expand-1-4";
    tr.innerHTML = '<td class="px-2 cursor-pointer">' +
      '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">' +
        '<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />'+
      '</svg>'+
    '</td>'+
    '<td class="py-4 font-semibold whitespace-nowrap px-2">Otros Egresos</td>'+
    '<td id="other-expenses" class="px-2 text-right">' + data["values"]["other_expenses"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td></td>'+
    '<td class="px-2"></td>';
    table_body.appendChild(tr);

    Object.keys(data.other_expenses_subcategories).forEach(function(object) {
      var tr = document.createElement('tr');
      tr.className = "expand-1-4-1 select-none";
        tr.innerHTML = '<td></td>' + 
        '<td class="py-4 font-semibold text-right profitability-subcategory whitespace-nowrap">' + object + '</td>' +
        '<td id="" class="px-2 text-right">' + data.other_expenses_subcategories[object]['Real'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>' +
        '<td></td>' +
        '<td class=""></td>';
        table_body.appendChild(tr);
        
    });

    //SALDO
    var tr = document.createElement('tr');
    tr.innerHTML = '<td class="px-2 cursor-pointer"></td>'+
    '<td class="py-4 font-bold whitespace-nowrap px-2">Saldo</td>'+
    '<td id="balance" class="px-2 text-right font-bold">' + data["values"]["balance"].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '</td>'+
    '<td></td>'+
    '<td class="px-2"></td>';
    table_body.appendChild(tr);

    calculateProgressBars(data);
    getRowValues();
  }

  function calculateProgressBars(data){
    let real, budget, percent, value;
    //INGRESOS POR VENTA
    real = parseMoneyStringToNumber($('#sale-income-real').text());
    budget = parseMoneyStringToNumber($('#sale-income-budget').text());
    if (budget != 0) {
      percent = Math.floor(real/budget.toFixed(2)*100);
    }else{
      percent = 0;
    }
    value = percent;
    $("#pb-sale-income").removeClass("progress-bar-green");
    $("#pb-sale-income").removeClass("progress-bar-blue");
    if(percent>100){
      $("#pb-sale-income").addClass("progress-bar-yellow");
      percent = 100;
    }else{
      $("#pb-sale-income").addClass("progress-bar-green");
    }
    $("#pb-sale-income").attr("style", "width: " +percent+ "%");
    $("#pb-sale-income-value").text(value+"%");
    
    //COSTO DE VENTA
    real = parseMoneyStringToNumber($('#sale-cost-real').text());
    budget = parseMoneyStringToNumber($('#sale-cost-budget').text());
    if (budget != 0) {
      percent = Math.floor(real/budget.toFixed(2)*100);
    }else{
      percent = 0;
    }
    value = percent;
    $("#pb-sale-cost").removeClass("progress-bar-green");
    $("#pb-sale-cost").removeClass("progress-bar-yellow");
    if(percent>100){
      $("#pb-sale-cost").addClass("progress-bar-red");
      percent = 100;
    }else{
      $("#pb-sale-cost").addClass("progress-bar-green");
    }
    $("#pb-sale-cost").attr("style", "width: " +percent+ "%");

    if((data["values"]["type_ppt"]!=null && data["values"]["type_ppt"]!=2) && (data.values.sale_cost_budget == 0) && (data.values.sale_cost_real>0)){
      $("#pb-sale-cost-value").removeClass("text-gray-900");
      $("#pb-sale-cost-value").addClass("text-red-700");
      $("#pb-sale-cost-value").text("! " + value +"%");
    }else{
      $("#pb-sale-cost-value").addClass("text-gray-900");
      $("#pb-sale-cost-value").removeClass("text-red-700");
      $("#pb-sale-cost-value").text(value+"%");
    }
    

    //UTILIDAD BRUTA
    real = parseMoneyStringToNumber($('#brute-utility-real').text());
    budget = parseMoneyStringToNumber($('#brute-utility-budget').text());
    if (budget != 0) {
      percent = Math.floor(real/budget.toFixed(2)*100);
    }else{
      percent = 0;
    }
    value = percent;
    $("#pb-brute-utility").removeClass("progress-bar-green");
    $("#pb-brute-utility").removeClass("progress-bar-blue");
    if(percent>100){
      $("#pb-brute-utility").addClass("progress-bar-yellow");
      percent = 100;
    }else{
      $("#pb-brute-utility").addClass("progress-bar-green");
    }
    $("#pb-brute-utility").attr("style", "width: " +percent+ "%");
    $("#pb-brute-utility-value").text(value+"%"); 

    //MANO DE OBRA
    real = parseMoneyStringToNumber($('#handwork-real').text());
    budget = parseMoneyStringToNumber($('#handwork-budget').text());
    if (budget != 0) {
      percent = Math.floor(real/budget.toFixed(2)*100);
    }else{
      percent = 0;
    }
    value = percent;
    $("#pb-handwork").removeClass("progress-bar-green");
    $("#pb-handwork").removeClass("progress-bar-yellow");
    if(percent>100){
      $("#pb-handwork").addClass("progress-bar-yellow");
      percent = 100;
    }else{
      $("#pb-handwork").addClass("progress-bar-green");
    }
    $("#pb-handwork").attr("style", "width: " +percent+ "%");
    $("#pb-handwork-value").text(value+"%");

    //INDIRECTOS DE OFICINA
    real = parseMoneyStringToNumber($('#office-indirects-real').text());
    budget = parseMoneyStringToNumber($('#office-indirects-budget').text());
    if (budget != 0) {
      percent = Math.floor(real/budget.toFixed(2)*100);
    }else{
      percent = 0;
    }
    value = percent;
    $("#pb-office-indirects").removeClass("progress-bar-green");
    $("#pb-office-indirects").removeClass("progress-bar-yellow");
    if(percent>100){
      $("#pb-office-indirects").addClass("progress-bar-yellow");
      percent = 100;
    }else{
      $("#pb-office-indirects").addClass("progress-bar-green");
    }
    $("#pb-office-indirects").attr("style", "width: " +percent+ "%");
    $("#pb-office-indirects-value").text(value+"%");

    //UTILIDAD NETA
    real = parseMoneyStringToNumber($('#net-utility-real').text());
    budget = parseMoneyStringToNumber($('#net-utility-budget').text());
    if (budget != 0) {
      percent = Math.floor(real/budget.toFixed(2)*100);
    }else{
      percent = 0;
    }
    value = percent;
    $("#pb-net-utility").removeClass("progress-bar-green");
    $("#pb-net-utility").removeClass("progress-bar-blue");
    if(percent>100){
      $("#pb-net-utility").addClass("progress-bar-yellow");
      percent = 100;
    }else{
      $("#pb-net-utility").addClass("progress-bar-green");
    }
    $("#pb-net-utility").attr("style", "width: " +percent+ "%");
    $("#pb-net-utility-value").text(value+"%"); 
  }

  function getRowValues(){
    let row_brute_utility_real = parseMoneyStringToNumber($("#sale-income-real").text())-parseMoneyStringToNumber($("#sale-cost-real").text());
    let row_brute_utility_budget = parseMoneyStringToNumber($("#sale-income-budget").text())-parseMoneyStringToNumber($("#sale-cost-budget").text());
    
    let row_net_utility_real = parseMoneyStringToNumber($("#sale-income-real").text())-parseMoneyStringToNumber($("#sale-cost-real").text()) - parseMoneyStringToNumber($("#office-indirects-real").text()) - parseMoneyStringToNumber($("#handwork-real").text());
    let row_net_utility_budget = parseMoneyStringToNumber($("#sale-income-budget").text())-parseMoneyStringToNumber($("#sale-cost-budget").text()) - parseMoneyStringToNumber($("#office-indirects-budget").text()) - parseMoneyStringToNumber($("#handwork-budget").text());

    let row_balance_real = parseMoneyStringToNumber($("#sale-income-real").text())-parseMoneyStringToNumber($("#sale-cost-real").text()) - parseMoneyStringToNumber($("#office-indirects-real").text()) - parseMoneyStringToNumber($("#handwork-real").text()) + parseMoneyStringToNumber($("#other-incomes").text()) - parseMoneyStringToNumber($("#other-expenses").text());
    //UTILIDAD BRUTA
    $("#brute-utility-real").text(parseNumberToString(row_brute_utility_real));
    $("#brute-utility-budget").text(parseNumberToString(row_brute_utility_budget));

    //UTILIDAD NETA
    $("#net-utility-real").text(parseNumberToString(row_net_utility_real));
    $("#net-utility-budget").text(parseNumberToString(row_net_utility_budget));
    
    //SALDO
    $("#balance-real").text(parseNumberToString(row_balance_real));

    if(row_brute_utility_budget>0){
      if(row_brute_utility_real<0){
        $("#brute-utility-real").addClass("text-red-600");
      }else{
        $("#brute-utility-real").removeClass("text-red-600");
      }
    }

    if(row_net_utility_budget>0){
      if(row_net_utility_real<0){
        $("#net-utility-real").addClass("text-red-600");
      }else{
        $("#net-utility-real").removeClass("text-red-600");
      }
    }

    if(row_balance_real<0){
      $("#balance").addClass("text-red-600");
    }else{
      $("#balance").removeClass("text-red-600");
    }
  
  }

  function parseMoneyStringToNumber(moneyString) {
    let cleanedString = moneyString.replace(/[^\d.-]/g, '');

    let parsedNumber = parseFloat(cleanedString);

    return parsedNumber;
  }

  function parseNumberToString(numero) {
    return '$' + numero.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  var myChartPie1 = null;
  var myChartPie2 = null;

  function updateSaleCostGraph(data){
    const subcategorias = Object.keys(data)
    const costos = subcategorias.map(function(subcategory) {
      return data[subcategory]["Real"]; 
    });

    const nueveMayoresNombres = subcategorias.slice(0, 9);
    const nueveMayoresCostos = costos.slice(0, 9);

    myChartPie2.data.labels = nueveMayoresNombres;
    myChartPie2.data.datasets[0].data = nueveMayoresCostos;

    myChartPie2.update();

    checkSubcategoriesCount();
  }

  function updateProfitabilityCompositionGraph(datares) {
    let graphSaleCost, graphSaleIncome, graphBruteUtility, graphHandwork, graphOfficeIndirects, graphNetUtility;
    graphSaleCost = parseFloat(datares.values.sale_cost_real);
    graphSaleIncome = parseFloat(datares.values.sale_income_real);
    graphBruteUtility = graphSaleIncome - graphSaleCost;
    graphHandwork = parseFloat(datares.values.handwork_real);
    graphOfficeIndirects = parseFloat(datares.values.office_indirects_real);
    graphNetUtility = graphBruteUtility - graphHandwork - graphOfficeIndirects;

    const costos = [
      graphSaleCost,
      graphHandwork,
      graphOfficeIndirects,
      graphNetUtility
    ];

    const nombres = ['Costo de venta', 'Mano de obra', 'Indirectos de oficina', 'Utilidad neta'];

    myChartPie1.data.labels = nombres;
    myChartPie1.data.datasets[0].data = costos;

    myChartPie1.update();
  }

  if ($('#pie-chart-projects-1').length) {
    setTimeout(function(){
      let sale_cost = parseMoneyStringToNumber($('#sale-cost-real').text());
      let handwork = parseMoneyStringToNumber($('#handwork-real').text());
      let office_indirects = parseMoneyStringToNumber($('#office-indirects-real').text());
      let net_utility = parseMoneyStringToNumber($('#net-utility-real').text());
      let phases_hours_values = [sale_cost, handwork, office_indirects, net_utility];
      let phases_hours_data = ['Costo de venta', 'Mano de obra', 'Indirectos de oficina', 'Utilidad neta'];
      var ctxPie = document.getElementById('pie-chart-projects-1').getContext('2d');
      myChartPie1 = new Chart(ctxPie, {
        type: 'doughnut',
        data: {
          labels: ['Costo de venta', 'Mano de obra', 'Indirectos de oficina', 'Utilidad neta'],
          datasets: [{
            data: phases_hours_values,
            backgroundColor: ['#f78a05', '#D004F9', '#0000FF', '#038031']
          }],
          labels: phases_hours_data
        },
        options: {
          responsive: true,
          legend: {
            position: 'right',
            labels: {
              fontSize: 12,
              generateLabels: function (chart) {
                var data = chart.data;
                var total = data.datasets[0].data.reduce(function (accumulator, currentValue) {
                  return accumulator + currentValue;
                }, 0);
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function (label, i) {
                    if (label.length > 15) {
                      label = label.substring(0, 15) + '...';
                    }
                    var percentage = ((data.datasets[0].data[i] / total) * 100).toFixed(2) + '%';
                    label += ' (' + percentage + ')';
                    return {
                      text: label,
                      fillStyle: data.datasets[0].backgroundColor[i],
                      hidden: isNaN(data.datasets[0].data[i]),
                      lineCap: 'butt',
                      lineDash: [],
                      lineDashOffset: 0,
                      lineJoin: 'miter',
                      lineWidth: 1,
                      strokeStyle: '#fff',
                      pointStyle: 'circle',
                      rotation: 0
                    };
                  });
                }
                return [];
              }
            }
          },
          title: {
            display: true,
            text: "Composición de Rentabilidad",
            fontSize: 16,
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.labels[tooltipItem.index] || '';

                if (label) {
                  label += ': ';
                }

                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                label += '$' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); // Formatea el valor como dinero ($100.00)

                var total = data.datasets[0].data.reduce(function(acc, val) {
                  return acc + val;
                });
                
                var percentage = ((value / total) * 100).toFixed(2) + '%';
                label += ' - ' + percentage;

                return label;
              }
            }
          },
          plugins: {}
        }
      });
    },500);
  }

  if ($('#pie-chart-projects-2').length) {
    let values = JSON.parse($('#subcategories-cost-sale-chart').val());
    let names = [];
    let amounts = [];
    let colors = ['#0000FF', '#038031', '#f78a05', '#ffdd03', '#7D3C98', '#D004F9', '#F86BFF', '#00FFF7', '#00FF00'];
    if (values != null) {
      names = values.map(function(x){ return x[0] });
      amounts = values.map(function(x){ return x[1] }); 
    }

    var ctxPie = document.getElementById('pie-chart-projects-2').getContext('2d');
    myChartPie2 = new Chart(ctxPie, {
      type: 'doughnut',
      data: {
        labels: names,
        datasets: [{
          data: amounts,
          backgroundColor: colors
        }],
        labels: names
      },
      options: {
        responsive: true,
        legend: {
          position: 'right',
          labels: {
            fontSize: 12,
            generateLabels: function (chart) {
              var data = chart.data;
              var total = data.datasets[0].data.reduce(function (accumulator, currentValue) {
                return accumulator + currentValue;
              }, 0);
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function (label, i) {
                  if (label.length > 12) {
                    label = label.substring(0, 12) + '...';
                  }
                  var percentage = ((data.datasets[0].data[i] / total) * 100).toFixed(2) + '%';
                  label += ' (' + percentage + ')';
                  return {
                    text: label,
                    fillStyle: data.datasets[0].backgroundColor[i],
                    hidden: isNaN(data.datasets[0].data[i]),
                    lineCap: 'butt',
                    lineDash: [],
                    lineDashOffset: 0,
                    lineJoin: 'miter',
                    lineWidth: 1,
                    strokeStyle: '#fff',
                    pointStyle: 'circle',
                    rotation: 0
                  };
                });
              }
              return [];
            }
          }
        },
        title: {
          display: true,
          text: "Costo de venta",
          fontSize: 16,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.labels[tooltipItem.index] || '';

              if (label) {
                label += ': ';
              }

              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              label += '$' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); // Formatea el valor como dinero ($100.00)

              var total = data.datasets[0].data.reduce(function(acc, val) {
                return acc + val;
              });
              
              var percentage = ((value / total) * 100).toFixed(2) + '%';
              label += ' - ' + percentage;

              return label;
            }
          }
        },
        plugins: {}
      }
    });
  }

  function getTopSubcategories(data) {

    let entries = Object.entries(data.sale_cost_subcategories);

    entries.sort((a, b) => b[1] - a[1]);

    let nueveCategorias = entries.slice(0, 9);

    let nueveCategoriasObjeto = Object.fromEntries(nueveCategorias);

    return nueveCategoriasObjeto;
  }

  function subcategoriesTitleLimit(){
    $('.profitability-subcategory').each(function() {
      var maxLength = 24;
      var text = $(this).text();
      
      if (text.length > maxLength) {
        var truncatedText = text.substring(0, maxLength) + '...';
        $(this).data('original-text', text);
        $(this).text(truncatedText);
        
        $(this).prop('title', text);
      }

    });
  }

  $('#profitability-expense-tab').click((event) => {
    hideTables();
    let nameProject = $("#project-name").text();
    let businessUnitName = $("#business-unit-name").text().trim();
    let project_id = $('#project-name').val();
    let type_record = 'Egresos';

    if ( tableProjectReportIncomeExpense != null) {
      tableProjectReportIncomeExpense.destroy();
      tableProjectReportIncomeExpense = null;
    }

    tableProjectReportIncomeExpense = $("#table-project-report-income-expense").DataTable({
      serverSide: true,
      scrollX: true,
      responsive: true,
      ajax: {
        url: '/records/rows',
        type: 'POST',
        data: {
          type_record: type_record,
          project_id: project_id,
          authenticity_token: AUTH_TOKEN
        },
        beforeSend: function(){
          $('#table-project-report-income-expense > tbody').html(
            '<tr class="odd">' +
              '<td valign="top" colspan="19" class="dataTables_empty">Cargando&hellip;</td>' +
            '</tr>'
          );
        },
        dataSrc: function(json){
          return json.data;
        },
      },
      columnDefs: [
        { className: "px-1 py-1 border border-gray-200 text-center whitespace-nowrap text-xs font-medium", "targets": "_all"},
      ],
      columns: [
        { data: "showed_id" },
        { data: 'date' },
        { data: 'project_r',
          render: function ( data, type, row, meta ) {
            if ( data == "oficina" ) {
              const lower = data.toLowerCase();
              dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);

              return dataCapitalize
            } else {
              return data
            }
          }
        },
        { data: 'category_r',
          render: function ( data, type, row, meta ) {
            switch ( data ) {
              case "Costos de venta":
                data = "Costo de venta";
                break;
              case "Costos financieros":
                data = "Costo integral financiero";
                break;
              default:
                break;
            }

            return data
          }
        },
        { data: 'subcategory_r',
          render: function ( data, type, row, meta ) {
            if ( data == "preventas" || data == "saldos" || data == "comisiones sobre venta" || data == "rentas garantizadas" || data == "subcontratos" ) {
              const lower = data.toLowerCase();
              dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);
              
              return dataCapitalize
            } else {
              return data
            }
          }
        },
        { data: 'concept_r' },
        { data: 'account_r' },
        { data: 'total_amount_r',
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: 'value_iva_r',
          render: function ( data, type, row, meta ) {
          if (data[2]) {
            switch (data[3]) {
              case "%":
                amount = (data[0] / (1 + (data[1] / 100)))*(data[1] / 100);
                amount = String(amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                amount = amount + " ("+data[1]+"%)";
                return amount
                break;
              case "$":
                percentage = (((data[0]/(data[0] - data[1])) - 1)) * 100;
                percentage = String(percentage.toFixed(2));
                amount = String(data[1].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                amount = amount;
                return amount+ " ("+percentage+"%)";
                break;
            }
          } else {
            return "$0.00 (0%)";
          }
        }},
        { data: 'subtotal',
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
      ],
      language: {
        lengthMenu: "Mostrar _MENU_ registros por página",
        zeroRecords: "Nada encontrado",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay registros disponibles",
        infoFiltered: "(filtrado de _MAX_ registros)",
        oPaginate: {
          sFirst: "Primero",
          sPrevious: "Anterior",
          sNext: "Siguiente",
          sLast: "Último",
        },
      },
      "order": [[ 1, 'asc' ]],
      lengthMenu: [
        [10, 25, 50, 100, 500, 1000],
        [10, 25, 50, 100, 500, 1000],
      ],
      dom: "Bltip",
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Egresos del proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend:'excelHtml5',
          title: `Egresos: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend: 'pdfHtml5',
          title: `Egresos: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
          exportOptions:{
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(6).footer()).html(
          'Total: '
        )
        $(api.column(7).footer()).html(
          sum(api.column(7, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
        )
        $(api.column(9).footer()).html(
          sum(api.column(9, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
        )
      }
    });

    hideTablesProfitability();
    $('#profitability-income-expense-div').show();
  });

  $("#hours-tab").on("click", function(){
    hideTables();
    $('#charts-div').hide();
    $('#hours-div').show();
  })

  $("#architect-tab").on("click", function(){
    $('#phase-div').hide();
    $('#architect-div').show();
    setTimeout(function(){ tableProjectHours.columns.adjust().draw() },100);
  })

  $("#phase-tab").on("click", function(){
    $('#architect-div').hide();
    $('#phase-div').show();
  })

  $("#indirect-tab").on("click", function(){
    hideTables();
    $('#charts-div').hide();
    $('#indirect-div').show();    
    setTimeout(function(){ tableProjectReportIndirect.columns.adjust().draw() },100);
  });

  $("#worker-projects-tab").on("click", function(){
    $('#worker-phases-div').hide();
    $('#proyectos-div').show();
    
  })

  $("#worker-phases-tab").on("click", function(){
    $('#proyectos-div').hide();
    $('#worker-phases-div').show();
    
  })

  if ( $("#table-project-report").length ) {
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    var tableProjectReport = $("#table-project-report").DataTable({
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "bLengthChange" : false,
      "bPaginate": false,
      dom: 'Blftp',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend:'excelHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
          customize: function (xlsx) {
            const sheet = xlsx.xl.worksheets['sheet1.xml'];
            let styledCategories = [];

            const titles = [
              'ingresos por venta',
              'costo de venta',
              'ingresos por venta - cv',
              'otros ingresos',
              'otros egresos',
              'saldo actual',
            ];

            $('row', sheet).each(function () {
              $(this).find('c').each(function () {
                const cell = $(this);
                const cellValue = cell.text().trim().toLowerCase();
                if (titles.includes(cellValue) && !styledCategories.includes(cellValue)) {
                  styledCategories.push(cellValue);
                  cell.attr('s', '2');
                }
              });
            });
          }
        },
        {
          extend: 'pdfHtml5',
          title: `Rentabilidad del proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}
                      Fecha de exportación: ${datetime}`,
          customize: function ( doc ) {
              doc.content[0].style = {bold: true, alignment: "center", fontSize: "20"};
              lengthRowsTable = doc.content[2].table.body.length;
              let styledCategories = [];
              
              const titles = [
                'ingresos por venta',
                'costo de venta',
                'ingresos por venta - cv',
                'otros ingresos',
                'otros egresos',
                'saldo actual',
              ];

              for (let i = 0; i < lengthRowsTable; i++) {
                let column = 1;

                titleRow = doc.content[2].table.body[i][column].text;
                titleRow = titleRow.toLowerCase();
                
                if (titles.includes(titleRow) && !styledCategories.includes(titleRow)){
                  styledCategories.push(titleRow);
                  styleRowDatatable(doc, i);
                }
              }

              if ( img64 != '' ) {
                doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
                } );
              }
            }
        }
      ],
      aaSorting: [],
      ordering: false,
      searching: false,
    });
  }

  if ( $("#report-project-architect-hours").length ) {
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    tableProjectHours = $('#report-project-architect-hours').DataTable({
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Horas trabajadas en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend:'excelHtml5',
          title: `Horas trabajadas en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend: 'pdfHtml5',
          title: `Horas trabajadas en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
          exportOptions:{
            columns: [0, 1],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );
      }
    });
  }

  if ( $("#report-project-phase-hours").length ) {
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    tableProjectFaseHours = $('#report-project-phase-hours').DataTable({
      scrollX:        true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Horas trabajadas en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend:'excelHtml5',
          title: `Horas trabajadas en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend: 'pdfHtml5',
          title: `Horas trabajadas en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
          exportOptions:{
            columns: [0, 1],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );
      }
    });
  }

  if ( $("#summary-worker-projects-hours").length ) {
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    tableSummaryProjectsHours = $('#summary-worker-projects-hours').DataTable({
      scrollX:        true,
      bPaginate: false,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Bltp',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Horas por proyecto`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
        },
        {
          extend:'excelHtml5',
          title: `Horas por proyecto`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Horas por proyecto`,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
          exportOptions:{
            columns: [0, 1],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );
      }
    });
  }

  if ( $("#summary-worker-phases-hours").length ) {
    nameProject = $("#project-name").text();

    var tableWorkerPhasesHours = $('#summary-worker-phases-hours').DataTable({
      scrollX:        true,
      bPaginate: false,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Bltp',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
        },
        {
          extend:'excelHtml5',
          title: `Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 1],
            rows: ':visible',
            
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );
      }
    });
  }

  $('#destroy-table').on('click',function(){
    tableWorkerPhasesHours.destroy();
  });

  $('#reinit-table').on('click',function(){
    setTimeout(function(){ reinitializeWorkerPhasesTable(); }, 500);
  });
  
  function reinitializeWorkerPhasesTable(){

    nameProject = $("#project-name").text();

    tableWorkerPhasesHours = $('#summary-worker-phases-hours').DataTable({
      scrollX:        true,
      bPaginate: false,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Bltp',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
        },
        {
          extend:'excelHtml5',
          title: `Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          footer: true,
        },
        {
          extend: 'pdfHtml5',
          title: `Proyecto: ${nameProject}`,
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [0, 1],
            rows: ':visible',
            
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(0).footer()).html(
          'Total: '
        )

        $(api.column(1).footer()).html(
          sumHours(api.column(1, {page:'current'}).data())
        );
      }
    });
  }

  function sumHours(arrayHours) {
    let total_minutes = 0;
    arrayHours.each(function(hour) {
      let element = hour.split(":");
      total_minutes += (parseInt(element[0]) * 60) + parseInt(element[1])
    });

    hours = Math.floor(total_minutes/60);
    minutes = total_minutes - (hours*60);

    if (minutes < 10) {
      minutes = '0' + String(minutes);
    };

    total_time = String(hours) + ':' + String(minutes);
    return total_time;
  }

  if ( $("#table-project-report-indirect").length ) {
    nameProject = $("#project-name").text();
    businessUnitName = $("#business-unit-name").text().trim();

    var tableProjectReportIndirect = $("#table-project-report-indirect").DataTable({
      scrollX: true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "order": [[ 0, 'decs' ]],
      dom: 'Blftip',
      buttons: [
        {
          extend: 'copyHtml5',
          title: `Factor de indirectos en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend:'excelHtml5',
          title: `Factor de indirectos en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
        },
        {
          extend: 'pdfHtml5',
          title: `Factor de indirectos en el proyecto: ${nameProject}`,
          messageTop: `Nombre de Unidad: ${businessUnitName}\n
                      Fecha de exportación: ${datetime}\n`,
          exportOptions:{
            columns: [0, 1, 2, 3],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        columnsWithSum = [2, 3];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });
  }

  if ( $("#table-report-account").length ) {
    var tableReportAccount = $("#table-report-account").DataTable({
      scrollY:        '68vh',
      paging:         false,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[-1], ["All"]],
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: "Saldos Grupo",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [0, 1, 2, 3, 4] 
          },
        },
        $.extend( true, {}, buttonCommonExcel, {
            extend: 'excelHtml5',
            footer: true,
            customize: function ( xlsx ) {
              var sheet = xlsx.xl.worksheets['sheet1.xml'];

              $('row:eq(0) c', sheet).attr( 's', '50' );
              $('row:eq(1) c', sheet).attr( 's', '50' );
            },
            title: "Saldos Grupo",
            exportOptions: {
              columns: [0, 1, 2, 3, 4],
            },
            messageTop: function() {
              return `Fecha de exportación: ${datetime}`
            },
        } ),
        $.extend( true, {}, buttonCommon, {
            extend: 'pdfHtml5',
            footer: true,
            title: "Saldos Grupo",
            messageTop: function() {
              return `Fecha de exportación: ${datetime}`
            },
            exportOptions: {
              columns: [0, 1, 2, 3, 4],
              stripNewlines: false,
            },
            customize: function ( doc ) {
              doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};
              lengthRowsTable = doc.content[2].table.body.length;

              for (let i = 0; i <  doc.content[2].table.body[0].length; i++) {
                doc.content[2].table.body[0][i].text = doc.content[2].table.body[0][i].text.replace("<br>","\n");
              }
              for (let i = 0; i < lengthRowsTable; i++) {
                let column = 0;

                titleRow = doc.content[2].table.body[i][column].text;
                titleRow = titleRow.toLowerCase();
                switch (titleRow) {
                  case "unidades de negocio":
                    styleRowDatatable(doc,i);
                    break;
                  case "proyectos inmobiliarios":
                    styleRowDatatable(doc,i);
                    break;
                  case "total":
                    styleRowDatatable(doc,i);
                    break;
                  default:
                    break;
                }
              }

              if ( img64 != '' ) {
                doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
                } );
              }
            }
        } )
      ],
      aaSorting: [],
      ordering: false,
      searching: false,
    });
  }

  if ($("#payment-report-table").length) {
    let tablePayment = $('#payment-report-table').DataTable({
      serverSide: true,
      ajax: {
        url: '/reports/rows_payment_clients',
        type: 'POST',
        data: {
          authenticity_token: AUTH_TOKEN
        },
        beforeSend: function(){
          $('#payment-report-table > tbody').html(
            '<tr class="odd">' +
              '<td valign="top" colspan="19" class="dataTables_empty">Cargando&hellip;</td>' +
            '</tr>'
          );
        },
        dataSrc: function(json){
  
          return json.data;
        },
      },
      createdRow: function (row, data, dataIndex) {
          if (data.total_difference <= 0) {
            class_paid = 'bg-green-400';
          } else {
            class_paid = 'bg-yellow-400';
          }
  
          $('td', row).eq(6).addClass(class_paid);
      },
      rowReorder: true,
      columnDefs: [
        { className: "client-id hidden", "targets": 0},
        { orderable: true, className: 'reorder border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap', targets: 1 },
        { orderable: false, className: "border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap", "targets": "_all"},
      ],
      columns: [
        { data: "id" },
        { data: "name" },
        { data: 'total_sales',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: 'total_records',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: 'total_difference',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: "percentage",
          render:function(data, type, row)
          {
            size_bar = data;
            if (data > 100) {
              size_bar = 100;
              color_bar = 'green';
            }else{
              color_bar = 'red';
            };

            return '<div class="progression">' +
              '<div class="progress-bar-width progress-bar-'+ color_bar +'" style="width: '+ size_bar +'%"></div>' +
            '</div>' +
            '<span>' + size_bar + '%</span>';
          }
        },
        { data: "total_difference",
          render:function(data, type, row)
          {
            if (data <= 0) {
              text_paid = 'Saldado';
            } else {
              text_paid = 'Por Cobrar';
            }

            return text_paid;
          }
        },
        { data: "id",
          render:function(data, type, row)
          {
            return '<a class="flex justify-center items-center space-x-2 md:py-1 align-middle text-white duration-500 hover:bg-green-500 rounded-lg transition duration-500 ease-in-out transform bg-current-color" href="/reports/detail_payment_report?id='+ data +'">' +
            '<span>Estado de Cuenta</span>' +
            '</a>';
          }
        },
      ],
      language: {
        lengthMenu: "Mostrar _MENU_ registros por página",
        zeroRecords: "Nada encontrado",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay registros disponibles",
        infoFiltered: "(filtrado de _MAX_ registros)",
        oPaginate: {
          sFirst: "Primero",
          sPrevious: "Anterior",
          sNext: "Siguiente",
          sLast: "Último",
        },
      },
      lengthMenu: [
        [10, 25, 50, 100, 500],
        [10, 25, 50, 100, 500],
      ],
      dom: 'Bltip',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Cobranza",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Cobranza",
          messageTop: `Fecha de exportación: ${datetime}`,
          footer: true,
          exportOptions:{
            columns: [1, 2, 3, 4, 5, 6],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
      "drawCallback": function(){
        columnsWithSum = [2, 3, 4];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });

    $("#search-client").keypress(function (e) {
      var code = (e.keyCode ? e.keyCode : e.which);
      if (code == 13) {
        searchWord();
      }
    });

    $('#search-payment-client').click(function () {
      searchWord();
    });
  
    const searchWord = () => {
      wordSelected = $('#search-client').val();

      tablePayment
        .search("");
      tablePayment
        .search(wordSelected)
        .draw();
    }
  }

  if ($("#payment-report-table-archost").length) {
    let tablePaymentArchost = $('#payment-report-table-archost').DataTable({
      serverSide: true,
      ajax: {
        url: '/reports/rows_payment_clients',
        type: 'POST',
        data: {
          authenticity_token: AUTH_TOKEN
        },
        beforeSend: function(){
          $('#payment-report-table-archost > tbody').html(
            '<tr class="odd">' +
              '<td valign="top" colspan="19" class="dataTables_empty">Cargando&hellip;</td>' +
            '</tr>'
          );
        },
        dataSrc: function(json){
  
          return json.data;
        },
      },
      createdRow: function (row, data, dataIndex) {
          if (data.total_difference_archost <= 0) {
            class_paid = 'bg-green-400';
          } else {
            class_paid = 'bg-yellow-400';
          }
  
          $('td', row).eq(6).addClass(class_paid);
      },
      rowReorder: true,
      columnDefs: [
        { className: "client-id hidden", "targets": 0},
        { orderable: true, className: 'reorder border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap', targets: 1 },
        { orderable: false, className: "border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap", "targets": "_all"},
      ],
      columns: [
        { data: "id" },
        { data: "name" },
        { data: 'total_project',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: 'total_records',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: "total_difference_archost",
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: "percentage_archost",
          render:function(data, type, row)
          {
            size_bar = data;
            if (data > 100) {
              size_bar = 100;
              color_bar = 'green';
            }else{
              color_bar = 'red';
            };

            return '<div class="progression">' +
              '<div class="progress-bar-width progress-bar-'+ color_bar +'" style="width: '+ size_bar +'%"></div>' +
            '</div>' +
            '<span>' + size_bar + '%</span>';
          }
        },
        { data: "total_project",
          render:function(data, type, row)
          {
            difference = data - row.total_records;

            if (difference <= 0) {
              text_paid = 'Saldado';
            } else {
              text_paid = 'Por Cobrar';
            }

            return text_paid;
          }
        },
        { data: "id",
          render:function(data, type, row)
          {
            return '<a class="flex justify-center items-center space-x-2 md:py-1 align-middle text-white duration-500 hover:bg-green-500 rounded-lg transition duration-500 ease-in-out transform bg-current-color" href="/reports/detail_payment_archost_report?client_id='+ data +'">' +
            '<span>Estado de Cuenta</span>' +
            '</a>';
          }
        },
      ],
      language: {
        lengthMenu: "Mostrar _MENU_ registros por página",
        zeroRecords: "Nada encontrado",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay registros disponibles",
        infoFiltered: "(filtrado de _MAX_ registros)",
        oPaginate: {
          sFirst: "Primero",
          sPrevious: "Anterior",
          sNext: "Siguiente",
          sLast: "Último",
        },
      },
      lengthMenu: [
        [10, 25, 50, 100, 500],
        [10, 25, 50, 100, 500],
      ],
      dom: 'Bltip',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Cobranza",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Cobranza",
          messageTop: `Fecha de exportación: ${datetime}`,
          footer: true,
          exportOptions:{
            columns: [1, 2, 3, 4, 5, 6],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
      "drawCallback": function(){
        columnsWithSum = [2, 3, 4];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });

    $("#search-client").keypress(function (e) {
      var code = (e.keyCode ? e.keyCode : e.which);
      if (code == 13) {
        searchWord();
      }
    });

    $('#search-payment-client').click(function () {
      searchWord();
    });
  
    const searchWord = () => {
      wordSelected = $('#search-client').val();

      tablePaymentArchost
        .search("");
        tablePaymentArchost
        .search(wordSelected)
        .draw();
    }
  }

  if ($("#payment-report-table-archost-project").length) {
    let tablePaymentArchostProject = $('#payment-report-table-archost-project').DataTable({
      serverSide: true,
      ajax: {
        url: '/reports/rows_payment_projects',
        type: 'POST',
        data: {
          authenticity_token: AUTH_TOKEN
        },
        beforeSend: function(){
          $('#payment-report-table-archost-project > tbody').html(
            '<tr class="odd">' +
              '<td valign="top" colspan="19" class="dataTables_empty">Cargando&hellip;</td>' +
            '</tr>'
          );
        },
        dataSrc: function(json){
  
          return json.data;
        },
      },
      createdRow: function (row, data, dataIndex) {
          if (data.total_difference_archost <= 0) {
            class_paid = 'bg-green-400';
          } else {
            class_paid = 'bg-yellow-400';
          }
  
          $('td', row).eq(6).addClass(class_paid);
      },
      rowReorder: true,
      columnDefs: [
        { className: "project-id hidden", "targets": 0},
        { orderable: true, className: 'reorder border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap', targets: 1},
        { orderable: false, className: "border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap", "targets": "_all"},
      ],
      columns: [
        { data: "id" },
        { data: "name" },
        { data: 'total_project',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: 'total_records',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: "total_difference_archost",
        render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: "percentage",
          render:function(data, type, row)
          {
            size_bar = data;
            if (data >= 100) {
              size_bar = 100;
              color_bar = 'green';
            }else{
              color_bar = 'red';
            };

            return '<div class="progression">' +
              '<div class="progress-bar-width progress-bar-'+ color_bar +'" style="width: '+ size_bar +'%"></div>' +
            '</div>' +
            '<span>' + size_bar + '%</span>';
          }
        },
        { data: "total_project",
          render:function(data, type, row)
          {
            difference = data - row.total_records;

            if (difference <= 0) {
              text_paid = 'Saldado';
            } else {
              text_paid = 'Por Cobrar';
            }

            return text_paid;
          }
        },
        { data: "id",
          render:function(data, type, row)
          {
            return '<a class="flex justify-center items-center space-x-2 md:py-1 align-middle text-white duration-500 hover:bg-green-500 rounded-lg transition duration-500 ease-in-out transform bg-current-color" href="/reports/detail_payment_archost_report?business_unit_project_id='+ data +'">' +
            '<span>Estado de Cuenta</span>' +
            '</a>';
          }
        },
      ],
      language: {
        lengthMenu: "Mostrar _MENU_ registros por página",
        zeroRecords: "Nada encontrado",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay registros disponibles",
        infoFiltered: "(filtrado de _MAX_ registros)",
        oPaginate: {
          sFirst: "Primero",
          sPrevious: "Anterior",
          sNext: "Siguiente",
          sLast: "Último",
        },
      },
      lengthMenu: [
        [10, 25, 50, 100, 500],
        [10, 25, 50, 100, 500],
      ],
      dom: 'Bltip',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Cobranza",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Cobranza",
          messageTop: `Fecha de exportación: ${datetime}`,
          footer: true,
          exportOptions:{
            columns: [1, 2, 3, 4, 5, 6],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
      "drawCallback": function(){
        columnsWithSum = [2, 3, 4];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });

    $("#search-project").keypress(function (e) {
      var code = (e.keyCode ? e.keyCode : e.which);
      if (code == 13) {
        searchWord();
      }
    });

    $('#search-payment-project').click(function () {
      searchWord();
    });
  
    const searchWord = () => {
      wordSelected = $('#search-project').val();

      tablePaymentArchostProject
        .search("");
        tablePaymentArchostProject
        .search(wordSelected)
        .draw();
    }
  }

  if ($("#payment-report-PUN-table").length) {
    $('#payment-report-PUN-table').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Blftip',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Estado de cuenta proyecto de unidad de negocio",
          messageTop: `Fecha de exportación: ${datetime}\n
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [0, 1, 2, 3, 4 , 5, 6, 7, 8, 9] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Estado de cuenta proyecto de unidad de negocio",
          messageTop: `Fecha de exportación: ${datetime}
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          exportOptions:{
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
      "drawCallback": function(){
        columnsWithSum = [3,4,5];
        var api = this.api();

        $(api.column(2).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });
  }

  if ($("#payment-record-PUN-table").length) {
    $('#payment-record-PUN-table').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Blftip',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Pagos Estado de cuenta proyecto de unidad de negocio",
          messageTop: `Fecha de exportación: ${datetime}\n
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [0, 1, 2] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Pagos Estado de cuenta proyecto de unidad de negocio",
          messageTop: `Fecha de exportación: ${datetime}
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          exportOptions:{
            columns: [0, 1, 2],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
      "drawCallback": function(){
        columnsWithSum = [2];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });
  }

  if ($("#payment-report-PINMB-table").length) {
    $('#payment-report-PINMB-table').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Blftip',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Estado de cuenta proyecto inmobiliario",
          messageTop: `Fecha de exportación: ${datetime}\n
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [0, 1, 2, 3, 4 , 5, 6, 7] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Estado de cuenta proyecto inmobiliario",
          messageTop: `Fecha de exportación: ${datetime}
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          exportOptions:{
            columns: [0, 1, 2, 3, 4, 5, 6, 7],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
      "drawCallback": function(){
        columnsWithSum = [3,4,5];
        var api = this.api();

        $(api.column(3).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });
  }

  if ($("#payment-record-PINMB-table").length) {
    $('#payment-record-PINMB-table').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Blftip',
      buttons: [
        'copyHtml5',
        {
          extend:'excelHtml5',
          title: "Pagos Estado de cuenta proyecto inmobiliario",
          messageTop: `Fecha de exportación: ${datetime}\n
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [0, 1, 2, 3] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Pagos Estado de cuenta proyecto inmobiliario",
          messageTop: `Fecha de exportación: ${datetime}
                      Cliente: ${$("#client-name").text()}`,
          footer: true,
          exportOptions:{
            columns: [0, 1, 2, 3],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
      "drawCallback": function(){
        columnsWithSum = [3];
        var api = this.api();

        $(api.column(2).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      }
    });
  }

  if($('#tab1').length && $('#tab2').length) {
    document.getElementById("tab1").onclick = function() {showTab(this)};
    document.getElementById("tab2").onclick = function() {showTab(this)};
  }
  
  function showTab(e) {
    let selectType = $(e).attr("data-select");
    if (selectType == 'tab1') {
        $("#tabstwo").hide();
        $("#tabsone").show();
        $("#tab1").addClass('text-blue-800 active');
        $("#tab2").removeClass('text-blue-800 active');
        $("#type-payment-report").text('PUN');
        upload_table();
  
    } else if (selectType == 'tab2') {

      $("#tabsone").hide();
      $("#tabstwo").show();
      $("#tab2").addClass('text-blue-800 active');
      $("#tab1").removeClass('text-blue-800 active').addClass('text-blue-400');
      $("#type-payment-report").text('PINMB');
      upload_table();
    }   
  }

  $('#payment-checkbox:checkbox').change(function(){
    upload_table();
  });

  function upload_table() {
    client_id = $('#client-id-payment').text();
    type_report = $("#type-payment-report").text(); 
    hide_payment = $('#payment-checkbox').is(':checked');
    var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');

    $.ajax({
      url: "/reports/update_payment_report",
      method: "POST",
      dataType: "json",
      data: {
        client_id: client_id,
        type_report: type_report,
        hide_payment: hide_payment,
        authenticity_token: AUTH_TOKEN
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        array_projects_sales = (response['projects_sales']);
        array_records = (response['records_client']);

        if (type_report == "PUN") {
          $('#payment-report-PUN-table').DataTable().clear().draw();
          array_projects_sales.forEach(data => {
            size_bar = parseFloat(data[6]);
            if ( size_bar >= 100) {
              size_bar = 100;
              color_bar = 'progress-bar-red';
            }else{
              color_bar = 'progress-bar-green';
            };
            
            percentage = ('<div class="progression">' +
            '<div class="progress-bar-width ' + color_bar + '" style="width:' + size_bar + '%">' +
            '</div>' +
            '</div>' +
            '<span>' + parseFloat(data[6]) + '%</span>');
            var DOM = $('#payment-report-PUN-table').DataTable().row.add( [
              data[0],
              data[1],
              data[2],
              data[3].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
              data[4].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
              data[5].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
              percentage,
              data[7],
              data[8],
              data[9]
            ] ).draw().node();
          
           $( DOM ).find('td').addClass('border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap');
           if(data[7] == 'Cobrado'){
            $( DOM ).find('td').eq(7).addClass('bg-green-400');
           }else{
            $( DOM ).find('td').eq(7).addClass('bg-yellow-400');
           }

          });

          $('#payment-record-PUN-table').DataTable().clear().draw();
          array_records.forEach(data => {
            var DOM = $('#payment-record-PUN-table').DataTable().row.add( [
              data[0],
              data[1],
              data[2].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
            ] ).draw().node();
          
           $( DOM ).find('td').addClass('border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap');

          });
        }

        if (type_report == "PINMB") {
          $('#payment-report-PINMB-table').DataTable().clear().draw();
          array_projects_sales.forEach(data => {
            size_bar = parseFloat(data[6]);
            if ( size_bar >= 100) {
              size_bar = 100;
              color_bar = 'progress-bar-red';
            }else{
              color_bar = 'progress-bar-green';
            };
            
            percentage = ('<div class="progression">' +
            '<div class="progress-bar-width ' + color_bar + '" style="width:' + size_bar + '%">' +
            '</div>' +
            '</div>' +
            '<span>' + parseFloat(data[6]) + '%</span>');
            var DOM = $('#payment-report-PINMB-table').DataTable().row.add( [
              data[0],
              data[1],
              data[2],
              data[3].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
              data[4].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
              data[5].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
              percentage,
              data[7]
            ] ).draw().node();
          
           $( DOM ).find('td').addClass('border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap');
           if(data[7] == 'Saldado'){
            $( DOM ).find('td').eq(7).addClass('bg-green-400');
           }else{
            $( DOM ).find('td').eq(7).addClass('bg-yellow-400');
           }

          });

          $('#payment-record-PINMB-table').DataTable().clear().draw();
          array_records.forEach(data => {
            var DOM = $('#payment-record-PINMB-table').DataTable().row.add( [
              data[0],
              data[1],
              data[2],
              data[3].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
            ] ).draw().node();
          
           $( DOM ).find('td').addClass('border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap');

          });
        }
        
      }
    });
  }

  $('#payment-archost-checkbox:checkbox').change(function(){
    upload_archost_table();
  });

  function upload_archost_table() {
    client_id = $('#client-id-payment').text();
    type_report = $("#type-payment-report").text(); 
    hide_payment = $('#payment-archost-checkbox').is(':checked');
    var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');

    $.ajax({
      url: "/reports/update_payment_archost_report",
      method: "POST",
      dataType: "json",
      data: {
        client_id: client_id,
        type_report: type_report,
        hide_payment: hide_payment,
        authenticity_token: AUTH_TOKEN
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        array_projects_sales = (response['projects_sales']);
        array_records = (response['records_client']);

        $('#payment-report-PUN-table').DataTable().clear().draw();
        array_projects_sales.forEach(data => {
          size_bar = parseFloat(data[6]);
          if ( size_bar >= 100) {
            size_bar = 100;
            color_bar = 'progress-bar-red';
          }else{
            color_bar = 'progress-bar-green';
          };
          
          percentage = ('<div class="progression">' +
          '<div class="progress-bar-width ' + color_bar + '" style="width:' + size_bar + '%">' +
          '</div>' +
          '</div>' +
          '<span>' + parseFloat(data[6]) + '%</span>');
          var DOM = $('#payment-report-PUN-table').DataTable().row.add( [
            data[0],
            data[1],
            data[2],
            data[3].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
            data[4].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
            data[5].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }),
            percentage,
            data[7],
            data[8],
            data[9]
          ] ).draw().node();
        
          $( DOM ).find('td').addClass('border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap');
          if(data[7] == 'Cobrado'){
          $( DOM ).find('td').eq(7).addClass('bg-green-400');
          }else{
          $( DOM ).find('td').eq(7).addClass('bg-yellow-400');
          }

        });

        $('#payment-record-PUN-table').DataTable().clear().draw();
        array_records.forEach(data => {
          var DOM = $('#payment-record-PUN-table').DataTable().row.add( [
            data[0],
            data[1],
            data[2].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          ] ).draw().node();
        
          $( DOM ).find('td').addClass('border border-gray-200 text-center text-sm px-1 py-1.5 whitespace-nowrap');

        });
      }
        
    });
  }

  if ($('#ip_income_statement_table').length) {
    let original_table_income = {}
    let original_table_expense = {}
    $('.tr-incomes').each(function(){
      original_table_income[this.cells[0].outerText] = {
        'Real': this.cells[1].outerText,
        'Planeado': this.cells[3].outerText,
        'Control': this.cells[5].outerText
      };
    });

    $('.tr-expenses').each(function(){
      original_table_expense[this.cells[0].outerText] = {
        'Real': this.cells[1].outerText,
        'Planeado': this.cells[3].outerText,
        'Control': this.cells[5].outerText
      };
    });

    $('.hide-data-table').click(function () {
      $('.tr-incomes').empty();
      $('.tr-expenses').empty();
      var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
      let array_subcategories_showed_incomes = []
      let array_subcategories_showed_expenses = []
      let total_incomes = [0, 0, 0];
      let total_expenses = [0, 0, 0];
      let total_real = convertCurrencyToDouble($('#project-utility')[0].cells[2].outerText)
      let total_planning = convertCurrencyToDouble($('#project-utility')[0].cells[4].outerText)
      let total_control = convertCurrencyToDouble($('#project-utility')[0].cells[6].outerText)
      let active_hidden = $('#hidden-variation-income-statement')[0].checked
      let class_hidden = ''

      if (active_hidden) {
        class_hidden = 'hidden'
      }

      $('.checkbox-subcategory-incomes').each(function(){
        if (this.checked) {
          array_subcategories_showed_incomes.push(this.value);
        }
      });

      $('.checkbox-subcategory-expenses').each(function(){
        if (this.checked) {
          array_subcategories_showed_expenses.push(this.value);
        }
      });

      array_subcategories_showed_incomes.forEach(function (value, i) {
        if (original_table_income[value] != undefined) {
          let real_value = convertCurrencyToDouble(original_table_income[value]['Real']);
          let planning_value = convertCurrencyToDouble(original_table_income[value]['Planeado']);
          let control_value = convertCurrencyToDouble(original_table_income[value]['Control']);
          total_incomes[0] += real_value;
          total_incomes[1] += planning_value;
          total_incomes[2] += control_value;
          bar_data = comparative_bar(real_value, planning_value);
          bar_data_control = comparative_bar(real_value, control_value);

          $('.tr-incomes')[i].innerHTML += '<td colspan="2" class="py-1 px-4 border-b border-grey-light text-sm whitespace-no-wrap">' + value + '</td>' +
          '<td class="py-1 px-4 border-b border-grey-light text-sm">' +
            '<span>' + original_table_income[value]['Real'] + '</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm">' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center">' +
            '<span>' + original_table_income[value]['Planeado'] + '</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
            '<div class="progression">' +
              '<div class="progress-bar-width ' + bar_data[2] + '" style="width: ' + bar_data[1] + '%">' +
              '</div>' +
            '</div>' +
            '<span>' + bar_data[0] + '%</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center">' +
            '<span>' + original_table_income[value]['Control'] + '</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
            '<div class="progression">' +
              '<div class="progress-bar-width ' + bar_data_control[2] + '" style="width: ' + bar_data_control[1] + '%">' +
              '</div>' +
            '</div>' +
            '<span>' + bar_data_control[0] + '%</span>' +
          '</td>';
        }
      });

      array_subcategories_showed_expenses.forEach(function (value, i) {
        if (original_table_expense[value] != undefined) {
          let real_value = convertCurrencyToDouble(original_table_expense[value]['Real']);
          let planning_value = convertCurrencyToDouble(original_table_expense[value]['Planeado']);
          let control_value = convertCurrencyToDouble(original_table_expense[value]['Control']);
          total_expenses[0] += real_value;
          total_expenses[1] += planning_value;
          total_expenses[2] += control_value;
          bar_data = comparative_bar(real_value, planning_value);
          bar_data_control = comparative_bar(real_value, control_value);
          
          $('.tr-expenses')[i].innerHTML = '<td colspan="2" class="py-1 px-4 border-b border-grey-light text-sm whitespace-no-wrap">' + value + '</td>' +
          '<td class="py-1 px-4 border-b border-grey-light text-sm">' +
            '<span>' + original_table_expense[value]['Real'] + '</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm">' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center">' +
            '<span>' + original_table_expense[value]['Planeado'] + '</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
            '<div class="progression">' +
              '<div class="progress-bar-width ' + bar_data[2] + '" style="width: ' + bar_data[1] + '%">' +
              '</div>' +
            '</div>' +
            '<span>' + bar_data[0] + '%</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center">' +
            '<span>' + original_table_expense[value]['Control'] + '</span>' +
          '</td>' +
          '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
            '<div class="progression">' +
              '<div class="progress-bar-width ' + bar_data_control[2] + '" style="width: ' + bar_data_control[1] + '%">' +
              '</div>' +
            '</div>' +
            '<span>' + bar_data_control[0] + '%</span>' +
          '</td>';
        }
      });

      bar_data = comparative_bar(total_incomes[0], total_incomes[1]);
      bar_data_control = comparative_bar(total_incomes[0], total_incomes[2]);

      $('.tr-incomes')[$('.tr-incomes').length -1].innerHTML += '<td colspan="2" class="py-1 px-4 border-b border-grey-light font-bold">Total</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm font-bold text-center">' +
        '<span> '+ total_incomes[0].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</span>' +
      '</td>' +
      '<td class="py-1 px-1 border-b border-grey-light text-sm font-bold text-center">' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm font-bold text-center">' +
        '<span>'+ total_incomes[1].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</span>' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
        '<div class="progression">' +
          '<div class="progress-bar-width ' + bar_data[2] + '" style="width: ' + bar_data[1] + '%">' +
          '</div>' +
        '</div>' +
        '<span>' + bar_data[0] + '%</span>' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm font-bold text-center">' +
        '<span>'+ total_incomes[2].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</span>' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
        '<div class="progression">' +
          '<div class="progress-bar-width ' + bar_data_control[2] + '" style="width: ' + bar_data_control[1] + '%">' +
          '</div>' +
        '</div>' +
        '<span>' + bar_data_control[0] + '%</span>' +
      '</td>';

      bar_data = comparative_bar(total_expenses[0], total_expenses[1]);
      bar_data_control = comparative_bar(total_expenses[0], total_expenses[2]);

      $('.tr-expenses')[$('.tr-expenses').length -1].innerHTML += '<td colspan="2" class="py-1 px-4 border-b border-grey-light font-bold">Total</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm font-bold text-center">' +
        '<span> '+ total_expenses[0].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</span>' +
      '</td>' +
      '<td class="py-1 px-1 border-b border-grey-light text-sm font-bold text-center">' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm font-bold text-center">' +
        '<span>'+ total_expenses[1].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</span>' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
        '<div class="progression">' +
          '<div class="progress-bar-width ' + bar_data[2] + '" style="width: ' + bar_data[1] + '%">' +
          '</div>' +
        '</div>' +
        '<span>' + bar_data[0] + '%</span>' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm font-bold text-center">' +
        '<span>'+ total_expenses[2].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'</span>' +
      '</td>' +
      '<td class="py-1 px-2 border-b border-grey-light text-sm text-center variations-hidden ' + class_hidden + '">' +
        '<div class="progression">' +
          '<div class="progress-bar-width ' + bar_data_control[2] + '" style="width: ' + bar_data_control[1] + '%">' +
          '</div>' +
        '</div>' +
        '<span>' + bar_data_control[0] + '%</span>' +
      '</td>';

      total_real += (total_incomes[0] - total_expenses[0]);
      total_planning += (total_incomes[1] - total_expenses[1]);
      total_control += (total_incomes[2] - total_expenses[2]);

      $('#total-saldo')[0].cells[1].innerHTML = total_real.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
      $('#total-saldo')[0].cells[3].innerHTML = total_planning.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
      $('#total-saldo')[0].cells[5].innerHTML = total_control.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });

      $.ajax({
        url: "/reports/create_var_pdf",
        method: "POST",
        dataType: "json",
        data: {
          authenticity_token: AUTH_TOKEN,
          array_subcategories_incomes: array_subcategories_showed_incomes,
          array_subcategories_expenses: array_subcategories_showed_expenses,
          total_incomes: total_incomes,
          total_expenses: total_expenses,
          total_real: total_real,
          total_planning: total_planning,
          total_control: total_control
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {

        }
      });
    });
    
    function comparative_bar(first_digit, second_digit) {
      let percentage = 0

      if (second_digit != 0) {
        percentage = (first_digit / second_digit)
      }

      percentage = (100 * percentage)
      percentage = percentage.toFixed(2)
      let size_bar = percentage

      if (percentage >= 100) {
        size_bar = 100
      }

      if (percentage <= 100) {
        color_bar = 'progress-bar-green'
      } else {
        color_bar = 'progress-bar-red'
      }

      return [percentage, size_bar, color_bar]
    }

    $('.refresh-data').click(function () {
      location.reload(); 
    });

    $('#hidden-options-income-statement').on('click',function(){
      $('#options-hidden').toggle('slide');
    });
  
    $('#hidden-variation-income-statement').on('click',function(){
      $('.variations-hidden').toggle('slide');
    });
  }

  if ($('#ip-absorcion-table').length) {

    title_document = 'Absorción';

    $('#ip-absorcion-table').DataTable({
      "language": {
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
      },
      "ordering": false,
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: title_document,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      Fecha de exportación: ${datetime}\n`,
          footer: true
        },
        {
          extend:'excelHtml5',
          title: title_document,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      Fecha de exportación: ${datetime}\n`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
            let last_row = $('row', sheet).length

            $('row:eq(0) c', sheet).attr( 's', '50' );
            $('row:eq(1) c', sheet).attr( 's', '50' );
            $('row:eq(2) c', sheet).attr( 's', '2' );
            $('row:last c', sheet).attr('s','2');
            $('c[r^="E'+last_row+'"], c[r^="F'+last_row+'"], c[r^="G'+last_row+'"]', sheet).attr('s','57');
            $('c[r^="H'+last_row+'"]', sheet).attr('s','60');
          },
          exportOptions: {
            rows: ':visible'
          }
        },
        {
          extend: 'pdfHtml5',
          title: title_document,
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: title_document,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.
                       Fecha de exportación: ${datetime}`,
          footer: true,
          exportOptions: {
            columns: function(idx, data, node) {
              if ($.inArray(idx, columnsVisible) < 0) {
                return false;
              }
              return true;
            },
            stripNewlines: false,
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        },
      ]
    });

  };

  if ($('#ip-sales-progress-table').length) {

    $('#ip-sales-progress-table').DataTable({
      "language": {
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
      },
      "ordering": false,
      dom: 'Bt',
      buttons: [
        {
          extend:'copyHtml5',
          title: 'Avance de ventas',
          messageTop: `Proyecto inmobiliario: ${$("#project-name").text()}.\n
                      Fecha de exportación: ${datetime}\n`
        },
        {
          extend:'excelHtml5',
          title: 'Avance de ventas',
          messageTop: `Proyecto inmobiliario: ${$("#project-name").text()}.   Fecha de exportación: ${datetime}\n`,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:eq(2) c[r^="C"]', sheet).attr( 's', '57' );
          }
        },
        {
          extend: 'pdfHtml5',
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: 'Avance de ventas',
          messageTop: `Proyecto inmobiliario: ${$("#project-name").text()}.
                       Fecha de exportación: ${datetime}`,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        },
      ]
    });

  };

});
